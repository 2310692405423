import React, { useState, useEffect } from "react";
import { RecorridoContainer } from "./style";
import House360 from "static/assets/images/backgrounds/home360.svg";
import Degrees from "static/assets/images/backgrounds/360-degrees.svg";
import { isMobile } from "react-device-detect";

const Recorrido = (props) => {
  const [url360, setUrl360] = useState();
  useEffect(() => {
    setUrl360(props.url_360);
  }, [props.url_360]);
  return (
    <RecorridoContainer>
      {url360 && (
        <div className="card">
          <div className="iconarea">
            <img src={House360} />
          </div>
          <div className="description360">
            <div className="tour">Tour Virtual 360º</div>
            <div className="indicator360">NUEVO</div>
            <div className="detailexpand">
              Conoce las áreas y detalles de este apartamento en un recorrido
              virtual.
            </div>
          </div>
          <div className="options">
            <a
              className="send-bt"
              href={url360}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="text">
                {isMobile ? "Hacer recorrido virtual" : "Recorrido virtual"}{" "}
              </div>
              <div className="icon-recorrido">
                <img src={Degrees} />
              </div>
            </a>
          </div>
        </div>
      )}
    </RecorridoContainer>
  );
};
export default Recorrido;
