import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./translations/i18nConfig";
import "./index.css";
import SalesDataContainer from "components/salesData/salesDataContainer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "components/header/header";
import GAListener from "components/GAListener/gaListener";
import { getCountry } from "utils/utils";

function App() {
  const trackingId = process.env.REACT_APP_HABI_GA;
  const { i18n } = useTranslation();
  const country = getCountry();

  useEffect(() => {
    const translation = `es-${country}`;
    i18n.changeLanguage(translation);
  }, [country, i18n]);

  return (
    <div className="App">
      <Router>
        <GAListener trackingId={trackingId}>
          <Route exact path="/">
            <Header />
          </Route>
          <Route exact path="/inmueble/:ParamName">
            <SalesDataContainer isHabiWeb={false} />
          </Route>
        </GAListener>
      </Router>
    </div>
  );
}

export default App;
