import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
  } from 'react-share';
  import { FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LinkedinIcon } from 'react-share';

const ModalShare = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const open = props.isOpen;
    const backdrop = props.backdrop;
    const goToUrl = props.url;
    setIsOpen(open);
    setBackdrop(backdrop);
    setUrl(goToUrl);
  });
  return (
    <Modal isOpen={isOpen} toggle={props.toggle} backdrop={backdrop}>
      <ModalHeader toggle={props.toggle}>Compartir</ModalHeader>
      <ModalBody style={{ display: 'flex', margin: 'auto' }}>
        <FacebookShareButton url={url} >
          <FacebookIcon round={true} />
        </FacebookShareButton>

        <WhatsappShareButton url={url}>
          <WhatsappIcon round={true}/>
        </WhatsappShareButton>

        <LinkedinShareButton url={url}>
          <LinkedinIcon round={true}/>
        </LinkedinShareButton>

        <TwitterShareButton url={url}>
          <TwitterIcon round={true}/>
        </TwitterShareButton>

        <TelegramShareButton url={url}>
          <TelegramIcon round={true}/>
        </TelegramShareButton>
      </ModalBody>
    </Modal>
  );
};

export default ModalShare;
