import styled from "styled-components";
import { size } from "StylesConstants";

export const ServicesWrapper = styled.div`
  position: relative;
  margin-top: 10%;
  .title-services {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #2d2d2d;
    text-align: left;
    margin-bottom: 0;
  }

  .graph {
    padding-left: 5.4% !important;
  }
  .titulo {
    .valor-inmueble {
      text-align: center;
      display: grid;
      grid-template-areas: "valor cuota";
      grid-template-columns: 50% 50%;
      .valor {
        grid-area: valor;
        text-align: right;
        padding-right: 20px;
      }
      .cuota {
        grid-area: cuota;
        text-align: left;
        padding-left: 20px;
      }
      p {
        margin-bottom: 0;
      }
      .title-inmueble {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #6d6970;
      }
      .price {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .note {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    color: #979499;
    font-size: 13px;
    line-height: 16px;
    padding-top: 32px;
  }

  .boxes {
    .valor-inmueble {
      text-align: left;
      display: grid;
      grid-template-areas: "valor cuota";
      p {
        margin-bottom: 0;
      }
      .title-inmueble {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #6d6970;
      }
      .price {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .graph {
      padding-left: 5.4% !important;
      .total-expenses {
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #6d6970;
        }
      }
      .charge-expenses {
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #424242;
        }
      }
      .montly-expenses {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: #6d6970;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-template-areas:
      "titulo titulo"
      "graph boxes"
      "note note";
    margin-top: 0;

    .titulo {
      grid-area: titulo;
      margin-bottom: 24px;
    }
    .graph {
      grid-area: graph;
      position: relative;
      padding-left: 2%;
    }
    .boxes {
      grid-area: boxes;
    }
    .note {
      grid-area: note;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #979499;
      text-align: left;
      padding-top: 40px;
    }
  }
`;
export const LabelWrapper = styled.section`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 10%;
`;
export const ExpensesWrapper = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0%;
  padding: 35% 0 0 0;
  text-align: center;

  .total-expenses {
    p {
      font-size: 13px;
      line-height: 24px;
      color: #6d6970;
      margin-bottom: 0;
    }
  }
  .charge-expenses {
    p {
      font-size: 17px;
      line-height: 24px;
      font-weight: bold;
      color: #424242;
      margin-bottom: 0;
    }
  }
  .montly-expenses {
    font-size: 13px;
    line-height: 24px;
    color: #6d6970;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${size.tablet}) {
    top: 0;
    left: 0;
    position: absolute;
    padding: 26% 0 0 0;
    text-align: center;
  }

  @media screen and (min-width: ${size.desktop}) {
    top: 0;
    left: 0;
    margin: 0%;
    padding: 30% 0 0 0;
    text-align: center;
  }
`;
