import React, { useState, useEffect, Fragment } from "react";
import { ActionsUser } from "../../style";
import recorridoIcon from "static/assets/images/icons/360Icon.svg";
import whatsappIcon from "static/assets/images/icons/whatsappv.svg";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import planosIcon from "static/assets/images/icons/planoIcon.svg";

const ActionsUsers = (props) => {
  const [img360, setImg360] = useState("");
  const [planos, setPlanos] = useState("");
  const [isPrivate, setIsPrivate] = useState("");
  const [telefono, setTelefono] = useState("");
  const [conjunto, setConjunto] = useState("");

  useEffect(() => {
    setImg360(props.img360);
    setIsPrivate(props.isPrivate);
    setPlanos(props.blueprints);
    setTelefono(props.telefono);
    setConjunto(props.conjunto);
  }, [
    props.img360,
    props.isPrivate,
    props.conjunto,
    props.telefono,
    props.blueprints,
  ]);
  return (
    <ActionsUser>
      {img360 && (
        <a
          className="default-button recorrido"
          href={img360}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={recorridoIcon} />
        </a>
      )}
      {/* 
        <div className="default-button video">
          Video
          <VideocamRoundedIcon className="camera" />
        </div> 
        */}
      {planos.length > 0 && (
        <div
          className="default-button planos"
          onClick={props.onClickBluePrints}
        >
          Planos
          <img src={planosIcon} className="plano" />
        </div>
      )}
      {(img360 || planos.length > 0) && <div className="separator"></div>}

      {/*    {isPrivate == 0 && (
        <Fragment>
          <a
            className="whatsapp-icon"
            href={
              "https://wa.me/57" +
              telefono +
              "?text=Quiero%20conocer%20más%20sobre%20este%20apartamento%20" +
              conjunto
            }
            target="_blank"
          >
            <img src={whatsappIcon} />
          </a>
          <a className="call-icon" href={"tel:+" + telefono}>
            <CallRoundedIcon className="call" />
          </a>
        </Fragment>
      )} */}
    </ActionsUser>
  );
};

export default ActionsUsers;
