import styled, { css } from "styled-components";
import {
  size,
  black,
  purple,
  grey,
  white,
  electricPurple800,
} from "StylesConstants";

export const SalesDataContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;

  .create-card-button {
    display: block;
    background-color: ${white};
    padding: 10px 20px;
    color: ${purple};
    font-size: 16px;
    margin: 0px 0px 10px 0;
    border-radius: 40px;
    height: 56px;
    width: 100%;
    padding: 10px 21px;
    border: 1px solid ${purple};
    transition: 0.3s all;
    font-weight: 700;
  }
  .create-card-button:hover {
    text-decoration: none;
    background-color: ${purple};
    color: ${white};
    transition: 0.3s all;
  }
  .create-card-button .icon {
    font-size: 37px;
    margin-top: 0px;
    margin-right: 10px;
    margin-left: -4px;
  }
  .button-contact {
    display: flex;
    background-color: ${purple};
    padding: 0 20px;
    color: ${white};
    font-size: 16px;
    margin: 10px 0;
    border-radius: 40px;
    height: 56px;
    width: 100%;
    padding: 10px 21px;

    .text-condicional {
      display: none;
    }
    .icon {
      display: none;
    }
    .zone-icon-wa {
    }
    .info-detail-wa {
      margin-left: 9.6px;
      margin-top: 2px;
      text-align: center;

      .title-item {
        display: none !important;
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px !important;
      }
      .title-item-mob {
        display: block;
        font-weight: 600 !important;
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 0px !important;
        text-align: center;
        margin-top: 2px;
      }
      .item-more {
        display: none !important;
        font-size: 12px;
      }
    }
  }
  .div-info {
    position: fixed;
    bottom: 0;
    background: linear-gradient(
      0deg,
      #ffffff 0%,
      #ffffff 77.6%,
      rgba(255, 255, 255, 0.61) 100%
    );
    height: 72px;
    display: grid;
    padding: 5px 10px;
    width: 100vw;
    grid-template-columns: 70% 30%;
    .info-item-visit {
      display: none !important;
    }
    .info-item-group {
      padding: 10px 0 10px 0px;
      margin: 1px 2px;
      display: grid;
      text-align: right;
      grid-template-columns: 1fr 1fr;

      .call-icon {
        padding: 5.4px 5px 7px 10px;
        text-align: left;
        .call {
          width: 28.06px;
          height: 28.06px;
          color: #000;
        }
      }
    }
    .contain-visit {
      width: 185px;
      margin: 18px 2px;

      .button-visit {
        padding: 10px 26px;
        width: 181px;
        height: 48px;
        background: #7c01ff;
        border-radius: 100px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        order: 0;
        align-self: center;
        white-space: nowrap;
        margin: 8px 2px;
        a {
          color: #fff;
        }
      }
    }

    .item-wa {
      width: 65% !important;
      margin: -3px 0 !important;
    }
    .info-item {
      margin: 15px 0;
      display: flex;
      line-height: 12px;
      font-size: 13px;
      width: 35%;
      text-align: left;
      .zone-icon {
        margin-top: -5px;
        padding: 0px 5px;
        display: none;
      }

      .title-item {
        font-weight: bold;
        display: block;
        margin-bottom: 7px;
      }
    }
    .button-contact {
      display: flex;
      background-color: ${purple};
      padding: 0 20px;
      color: ${white};
      font-size: 16px;
      margin: 10px 0;
      border-radius: 40px;
      height: 56px;
      width: 100%;
      padding: 10px 21px;

      .text-condicional {
        display: none;
      }
      .icon {
        display: none;
      }
    }
  }
  .sticky-wrapper {
    position: relative;
  }
  .item-share {
    position: absolute;
    top: 0;
    float: left;
  }

  .sticky .sticky-inner {
    display: inline-flex;
    grid-template-columns: 1fr;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${white};
    height: 50px;
    border-bottom: 1px solid ${grey};

    .menu {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      display: inline-flex;
      width: 100%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .left-icon {
        margin-top: 8px;
        position: absolute;
        left: -7px;
        width: 30px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0.9791183294663574) 75%,
          rgba(255, 255, 255, 0.7517401392111369) 100%
        );
      }
      .oculto {
        display: none;
      }
      .right-icon {
        margin-top: 8px;
        position: absolute;
        right: 0;
        width: 30px;
        background-color: #ffffffc4;
      }

      .item-sticky {
        height: 100%;
        padding: 15px 0 9px 0;
        margin: 0 24px 0 0;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        line-height: 13px;
        color: ${black};
        opacity: 0.8;

        &:hover {
          cursor: pointer;
        }
      }

      .active {
        color: ${purple};
        font-weight: bold;
        border-bottom: 3px solid ${purple};
      }
    }
    .options-share {
      margin-left: auto;
      margin-right: auto;
      text-align: right;
      width: 20%;

      .item-sticky {
        height: 100%;
        padding: 15px 0 9px 0;
        margin: 0 17px;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        color: ${purple};
        opacity: 0.8;

        &:hover {
          cursor: pointer;
        }

        .icon {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          margin: -3px 5px 0 0;
        }
      }
    }
  }

  .loader {
    position: absolute;
    margin: auto;
    left: calc(100vw - 68%);
    top: 40%;
  }
  .sales-container {
    display: grid;
    grid-template-areas:
      "gallery"
      "actions"
      "title"
      "description"
      "recorrido"
      "detailCard"
      "details"
      "features"
      "nearplaces"
      "map"
      "planos"
      "calculator"
      "createcard";
    width: 100%;
    padding: 15px;
    margin-bottom: 6%;

    .main-title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: ${black};
      margin-bottom: 20px;
    }

    .title {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
      color: ${purple};

      .section-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        color: ${purple};
        margin: 16px 0px;
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .button-contact {
      display: flex;
      background-color: ${purple};
      padding: 0 20px;
      color: ${white};
      font-size: 16px;
      margin: 10px 0;
      border-radius: 40px;
      height: 56px;
      width: 100%;
      padding: 10px 21px;

      .text-condicional {
        display: none;
      }
      .icon {
        display: none;
      }
      .zone-icon-wa {
      }
      .info-detail-wa {
        margin-left: 9.6px;
        margin-top: 2px;
        text-align: center;

        .title-item {
          display: block !important;
          font-weight: 600 !important;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0px !important;
        }
        .title-item-mob {
          display: none;
        }
        .item-more {
          display: block !important;
          font-size: 12px;
        }
      }
    }
    .div-info {
      position: fixed;
      bottom: 0;
      background: linear-gradient(
        0deg,
        #ffffff 0%,
        #ffffff 77.6%,
        rgba(255, 255, 255, 0.61) 100%
      );
      height: 72px;
      display: grid;
      padding: 5px 10px;
      width: 100vw;
      grid-template-columns: 1fr 1fr;
      .info-item-visit {
        display: none !important;
      }
      .info-item-group {
        padding: 10px 0 10px 15px;
        margin: 1px 2px;
        text-align: right;

        .call-icon {
          padding: 5.4px 0 6px 22px;
          .call {
            width: 28.06px;
            height: 28.06px;
            color: #000;
          }
        }
      }
      .contain-visit {
        width: 100%;
        margin: 18px 2px;

        .button-visit {
          padding: 10px 26px;
          width: 181px;
          height: 48px;
          background: #7c01ff;
          border-radius: 100px;
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          order: 0;
          align-self: center;
          white-space: nowrap;
          margin: 8px 2px;
          a {
            color: #fff;
          }
        }
      }

      .item-wa {
        width: 65% !important;
        margin: -3px 0 !important;
      }
      .info-item {
        margin: 15px 0;
        display: flex;
        line-height: 12px;
        font-size: 13px;
        width: 35%;
        text-align: left;
        .zone-icon {
          margin-top: -5px;
          padding: 0px 5px;
          display: none;
        }

        .title-item {
          font-weight: bold;
          display: block;
          margin-bottom: 7px;
        }
      }
      .button-contact {
        display: flex;
        background-color: ${purple};
        padding: 0 20px;
        color: ${white};
        font-size: 16px;
        margin: 10px 0;
        border-radius: 40px;
        height: 56px;
        width: 100%;
        padding: 10px 21px;

        .text-condicional {
          display: none;
        }
        .icon {
          display: none;
        }
      }
    }
    .loader {
      position: absolute;
      margin: auto;
      left: calc(100vw - 60%);
      top: 40%;
    }
    .sales-container {
      padding-top: 34px;
      .main-title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
      }

      .title {
        .section-title {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 12px;
        }
      }

      .sticky .sticky-inner {
        display: inline-flex;
        grid-template-columns: 1fr 1fr;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: ${white};
        height: 50px;
        border-bottom: 1px solid ${grey};

        .menu {
          margin-left: 0;
          margin-right: 0;
          text-align: left;
          display: inline-flex;
          width: auto;

          .item-sticky {
            height: 100%;
            padding: 15px 0 9px 0;
            margin: 0 24px 0 0;
            font-weight: 600;
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            color: ${black};
            opacity: 0.8;

            &:hover {
              cursor: pointer;
            }
          }

          .active {
            color: ${purple};
            font-weight: bold;
            border-bottom: 3px solid ${purple};
          }
        }
        .options-share {
          margin-left: auto;
          margin-right: auto;
          text-align: right;
          width: 20%;
          .item-sticky {
            height: 100%;
            padding: 15px 0 9px 0;
            margin: 0 17px;
            font-weight: 600;
            font-style: normal;
            font-size: 16px;
            line-height: 16px;
            color: ${purple};
            opacity: 0.8;

            &:hover {
              cursor: pointer;
            }

            .icon {
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              margin: -3px 5px 0 0;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .button-contact {
      display: flex;
      background-color: ${white};
      padding: 0 20px;
      color: ${white};
      font-size: 16px;
      margin: 0 2% 0 2%;
      border-radius: 40px;
      border: 1px solid #eaeaeb;
      height: 48px;
      width: 257px;
      padding: 8px 18px;

      .text-condicional {
        display: none;
      }
      .icon {
        display: none;
      }
      .zone-icon-wa {
        img {
          height: 28.8px;
          width: 28.8px;
          margin-right: 10px;
        }
      }
      .info-detail-wa {
        margin-left: 0 !important;
        margin-top: 2px;
        text-align: center;

        .title-item {
          display: block;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 17px;
          text-align: center;
          margin-top: 5px;
          color: #252129;
        }
        .title-item-mob {
          display: none;
        }
        .item-more {
          font-size: 12px;
        }
      }
    }

    .button-visit-dsk {
      display: flex;
      background-color: #7c01ff;
      padding: 0 20px;
      color: ${white};
      font-size: 16px;
      margin: 10px 2% 0 2%;
      border-radius: 40px;
      height: 56px;
      width: 257px;
      padding: 16px 60px;
      &:hover {
        cursor: pointer;
      }

      .title-item {
        display: block;
        font-style: normal !important;
        font-weight: 600;
        font-size: 17px;
        font-family: Open Sans;
        line-height: 21px !important;
        margin-bottom: 0px !important;
        color: #fff;
      }
    }
    .div-info {
      display: none;
    }
    .loader {
      position: absolute;
      margin: auto;
      left: calc(100% - 56%);
      top: 40%;
    }
    .sales-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 8%;
      grid-template-areas:
        "gallery gallery"
        "actions details"
        "description details"
        "recorrido details"
        "detailCard details"
        "features details"
        "nearplaces details"
        "map details"
        "planos details"
        "calculator details"
        "createcard details";
      width: 100%;
      padding: 34px 32px 20px 32px;
      .main-title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 58px;
        margin-bottom: 16px;
      }

      .title {
        .section-title {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        }
      }

      .sticky .sticky-inner {
        display: grid;
        grid-template-columns: 60% 40%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: ${white};
        height: 50px;
        border-bottom: 1px solid ${grey};
        border-top: 1px solid ${grey};

        .menu {
          /* margin-left: auto;
          margin-right: auto; */
          text-align: left;
          display: inline-flex;
          width: auto;

          .item-sticky {
            height: 100%;
            padding: 15px 0 9px 0;
            margin: 0 34px 0 0;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: ${black};
            opacity: 0.8;

            &:hover {
              cursor: pointer;
            }
          }

          .active {
            color: ${purple};
            font-weight: bold;
            border-bottom: 3px solid ${purple};
          }
        }
        .options-share {
          margin-left: auto;
          margin-right: auto;
          text-align: right;
          width: 20%;
          text-align: right;
          .item-sticky {
            height: 100%;
            padding: 15px 0 9px 0;
            margin: 0 17px;
            font-weight: 600;
            font-style: normal;
            font-size: 16px;
            line-height: 16px;
            color: ${purple};
            opacity: 0.8;
            display: flex;

            &:hover {
              cursor: pointer;
            }

            .icon {
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              margin: -3px 5px 0 0;
            }
          }
        }
      }
    }
  }
`;
export const MapaStreet = styled.div`
  grid-area: otro;
  width: 180px;
  height: 120px;
`;
export const Title = styled.div`
  grid-area: title;
  .main-title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
    text-align: left;
  }
  @media screen and (min-width: ${size.tablet}) {
    .main-title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 58px;
      margin-bottom: 16px;
      text-align: left;
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .main-title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 58px;
      margin-bottom: 16px;
      text-align: left;
    }
  }
`;

export const Intro = styled.div`
  border-bottom: 1px solid ${grey};
  padding-bottom: 15px;
  margin-bottom: 30px;
  .ubication {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.8;
  }
  .items-properties {
    display: grid;
    text-align: left;
    .group-item {
      width: 100%;
      display: flex;
      padding: 5px;
      .item-prop {
        margin-right: 30px;
        font-size: 16px;
        color: ${black};
        opacity: 0.88;
        line-height: 24px;
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .items-properties {
      display: flex;
      text-align: left;
      .item-prop {
        margin-right: 30px;
        font-size: 16px;
        color: ${black};
        opacity: 0.88;
        line-height: 24px;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .items-properties {
      display: flex;
      text-align: left;
      .item-prop {
        margin-right: 30px;
        font-size: 16px;
        color: ${black};
        opacity: 0.88;
        line-height: 24px;
      }
    }
  }
`;

export const Gallery = styled.div`
  grid-area: gallery;
`;

export const ActionsUser = styled.div`
  grid-area: actions;
  display: inline-flex;

  .default-button {
    width: auto;
    height: 40px;
    left: 112px;
    top: 430px;
    padding: 4px 24px;
    background: #ffffff;
    border: 1px solid #eaeaeb;
    box-sizing: border-box;
    border-radius: 100px;
    margin-left: 0;
    display: inline-flex;
  }
  .recorrido {
    margin-left: 0;
  }
  .video {
    padding: 6px 24px !important;
    margin-right: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    .camera {
      margin-top: 2px;
      margin-left: 11px;
    }
  }

  .planos {
    padding: 6px 24px !important;
    margin-right: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 5px;
    line-height: 28px;
    &:hover {
      cursor: pointer;
    }
    .plano {
      margin-top: 2px;
      margin-left: 11px;
    }
  }
  .whatsapp-icon {
    padding-right: 10px;
  }

  .separator {
    border-left: 1px solid ${grey};
    height: 32px;
    margin-top: 4px;
    margin-right: 10px;
    margin-left: 12px;
  }
  .call-icon {
    padding: 5.4px 6px 6px 5.4px;
    .call {
      width: 28.06px;
      height: 28.06px;
      color: #000;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    display: inline-flex;
    padding: 10px 0;
    .default-button {
      width: auto;
      height: 40px;
      left: 112px;
      top: 430px;
      padding: 4px 24px;
      background: #ffffff;
      border: 1px solid #eaeaeb;
      box-sizing: border-box;
      border-radius: 100px;
      margin-left: 16px;
      display: inline-flex;
    }
    .recorrido {
      margin-left: 0;
    }
    .video {
      padding: 6px 24px !important;
      margin-right: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      .camera {
        margin-top: 2px;
        margin-left: 11px;
      }
    }

    .planos {
      padding: 6px 24px !important;
      margin-right: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      .plano {
        margin-top: 2px;
        margin-left: 11px;
      }
    }

    .whatsapp-icon {
      padding-right: 10px;
    }

    .separator {
      border-left: 1px solid ${grey};
      height: 32px;
      margin-top: 4px;
      margin-right: 18px;
      margin-left: 20px;
    }
    .call-icon {
      padding: 5.4px 6px 6px 5.4px;
      .call {
        width: 28.06px;
        height: 28.06px;
        color: #000;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
  }
`;

export const DescriptionContainer = styled.div`
  grid-area: description;
  margin: 8px 0px;
  text-align: left;

  .title-description {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 16px;
    color: ${black};
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    border-bottom: 1px solid ${grey};
    padding-bottom: 20px;
    width: 90vw;
    margin-right: -40px;
    margin-bottom: 30px;
    word-wrap: break-word;
  }

  .verification-badge {
    text-align: left;
    padding: 0 0 23px 0;
    top: 405px;
    grid-template-columns: 35% 65%;
    display: grid;
    margin-bottom: 23px;
    border-bottom: 1px solid ${grey};

    .img-bd {
      text-align: center;
      margin-top: -5px;
      margin-bottom: 18px;
    }

    .description-area {
      text-align: center;
    }
    .description-bd {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      text-align: left;
      color: #6d6970;
      opacity: 0.8;
    }
  }
  .details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    text-align: left;
    border-bottom: 1px solid ${grey};

    .title-detail {
      color: ${black};
      opacity: 0.8;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }

    .description-detail {
      color: ${black};
      opacity: 0.9;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 24px;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .verification-badge {
      text-align: left;
      padding: 0 15px 23px 15px;
      top: 405px;
      grid-template-columns: 20% 80%;
      display: grid;
      margin-bottom: 23px;
      border-bottom: 1px solid ${grey};

      .img-bd {
        text-align: center;
        margin-top: -5px;
      }

      .description-bd {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #6d6970;
        opacity: 0.8;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    margin: 0px;

    .description {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      border-bottom: 1px solid ${grey};
      padding-bottom: 20px;
      width: 100%;
      margin-right: -40px;
      margin-bottom: 30px;
      word-wrap: break-word;
    }
  }
`;

export const DetailCard = styled.div`
  grid-area: detailCard;
  margin: 8px 0px;

  .title-description {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 16px;
    color: ${black};
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    border-bottom: 1px solid ${grey};
    padding-bottom: 20px;
    width: 90vw;
    margin-right: -40px;
    margin-bottom: 30px;
    word-wrap: break-word;
  }

  .verification-badge {
    text-align: left;
    padding: 0 0 23px 0;
    top: 405px;
    grid-template-columns: 30% 70%;
    display: grid;
    margin-bottom: 23px;
    border-bottom: 1px solid ${grey};

    .img-bd {
      text-align: left;
      margin-top: -5px;
      margin-bottom: 18px;
    }

    .description-area {
      text-align: center;
    }
    .description-bd {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      text-align: left;
      color: #6d6970;
      opacity: 0.8;
    }
  }
  .details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    text-align: left;
    border-bottom: 1px solid ${grey};

    .title-detail {
      color: ${black};
      opacity: 0.8;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }

    .description-detail {
      color: ${black};
      opacity: 0.9;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 24px;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .verification-badge {
      text-align: left;
      padding: 0 15px 23px 15px;
      top: 405px;
      grid-template-columns: 20% 80%;
      display: grid;
      margin-bottom: 23px;
      border-bottom: 1px solid ${grey};

      .img-bd {
        text-align: center;
        margin-top: -5px;
      }

      .description-bd {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #6d6970;
        opacity: 0.8;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    margin: 0px;

    .description {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      border-bottom: 1px solid ${grey};
      padding-bottom: 20px;
      width: 100%;
      margin-right: -40px;
      margin-bottom: 30px;
      word-wrap: break-word;
    }
  }
`;

export const DetailsContainer = styled.div`
  grid-area: details;

  .about-us {
    text-align: left;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    padding: 2px;

    .contact-card {
      border: 1px solid #cecece;
      border-radius: 8px;
      padding: 15px 20px 0px 20px;
      margin-bottom: 10px;

      .brand {
        display: flex;
        .brand-name {
          padding-left: 10px;
          padding-top: 10px;
        }
      }

      .info-item {
        margin: 15px 0;
        display: flex;
        line-height: 20px;
        .zone-icon {
          margin-top: -5px;
          padding: 0px 5px;
        }

        .title-item {
          font-weight: bold;
          display: block;
          line-height: 14px;
          margin-bottom: 7px;
        }
      }
    }
  }
  .verification-badge {
    text-align: left;
    border-radius: 8px;
    padding: 0px 20px 20px 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 405px;

    .img-bd {
      margin-bottom: 10px;
    }

    .description-bd {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #6d6970;
      opacity: 0.8;
    }
  }

  .container-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 2px solid ${grey};
    border-radius: 22px;

    .data-list-item {
      border-bottom: 1px solid #f1f1f1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0px 10px;
      padding: 10px;

      .left {
        color: #b8b8b8;
        font-weight: 500;
        justify-self: baseline;
      }

      .right {
        justify-self: end;
      }
    }
  }
`;

export const FeaturesContainer = styled.div`
  grid-area: features;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: ${({ border }) => (border ? "1px" : "0px")} solid ${grey};
  .title-description {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 16px;
    color: ${black};
    margin-top: 20px;
  }
  .container-features {
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .data-list-item {
      display: grid;
      grid-template-columns: 35px 1fr;
      padding: 10px 0;

      .icon {
        justify-self: left;
        align-self: left;
        opacity: 0.9;
      }

      .feature {
        justify-self: baseline;
        align-self: left;
        text-align: left;
        padding-bottom: 24px;
        .feature-text {
          font-size: 16px;
          line-height: 24px;
          color: ${black};
          opacity: 0.9;
        }
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .container-features {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .container-features {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const NearPlacesContainer = styled.div`
  ${({ hide }) =>
    hide &&
    css`
       {
        display: none;
      }
    `}
  grid-area: nearplaces;
  padding-bottom: 10px;
  margin-bottom: 23px;
  border-bottom: 1px solid ${grey};

  .title-description {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 16px;
    color: ${black};
    margin-top: 15px;
  }
  .container-places {
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 22px;
    display: grid;
    grid-template-columns: 1fr;

    .title-category {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #2d2d2d;
      opacity: 0.9;
      display: grid;
      grid-template-columns: 30px auto;
    }
    .title-category p:first-letter {
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }

    .item-category {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: #6d6970;
      margin-left: 30px;
      margin-bottom: 9px;
      opacity: 0.9;
    }

    .group-near {
      margin-bottom: 16px;
      text-align: left;
    }
    .data-list-item {
      display: grid;
      grid-template-columns: 35px 1fr;
      padding: 10px 0;

      .icon {
        justify-self: left;
        align-self: left;
        opacity: 0.9;
      }

      .feature {
        justify-self: baseline;
        align-self: left;
        text-align: left;
        padding-bottom: 24px;
        .feature-text {
          font-size: 16px;
          line-height: 24px;
          color: ${black};
          opacity: 0.9;
        }
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .container-places {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .container-places {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const InfoCreation = styled.div`
  grid-area: createcard;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  margin-bottom: 0px !important;

  .create-card-action {
    width: 100%;
    text-align: center;
    display: block;
    position: relative;
    height: 88px;

    .copy-action {
      padding-right: 8px;
    }
    .text-action {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: ${electricPurple800};
      display: inline-flex;
    }
    .highlighted {
      position: relative;
      color: ${electricPurple800};
      display: flex;
      padding: 0 4px;
      p {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          background-color: #f2e6ff;
          border-color: #f2e6ff;
          border-style: solid;
          border-width: 5.5px 0;
          height: 28px;
          bottom: 0;
          left: 0;
          margin-top: -1em;
          width: 229px;
          border-radius: 1px;
          z-index: -1;
        }
      }
      &::after {
        content: "";
        position: absolute;
        border-color: #f2e6ff;
        border-style: solid;
        border-width: 5.5px 0;
        height: 11px;
        bottom: 0;
        left: 0;
        margin-top: -1em;
        width: 229px;
        border-radius: 1px;
        z-index: -1;
      }
    }

    .float-info {
      display: none;
      position: absolute;
      width: 96% !important;
      height: 392px !important;
      background: #ffffff;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      top: -361px !important;
      left: 2% !important;
      font-family: sans-serif;
      text-align: left;
      padding: 32px 24px;
      z-index: 0;
      &::after {
        content: "";
        position: absolute;
        width: 63px;
        height: 40.09px;
        background: #ffffff;
        box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        left: 60% !important;
        top: 91% !important;
        transform: rotate(45deg);
      }
    }
    p {
      margin-bottom: 0;
    }
    .header-info {
      padding-bottom: 24px;
      .title-post {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #2e2933;
      }
    }
    .paragraph-info {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: #565258;
    }
    .legend {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: #6d6970;
    }
    .info-action {
      padding-left: 14px;
      margin-top: -15px !important;
    }

    .info-active {
      display: block !important;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    padding-top: 48px !important;
    padding-bottom: 10px !important;
    margin-bottom: 0 !important;
    .create-card-action {
      text-align: center;
      .text-action {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: ${electricPurple800};
        display: inline-flex;
      }
      .highlighted {
        position: relative;
        color: ${electricPurple800};
        display: flex;
        padding: 0 4px;
        p {
          margin-bottom: 0;
        }

        &:hover {
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            background-color: #f2e6ff;
            border-color: #f2e6ff;
            border-style: solid;
            border-width: 5.5px 0;
            height: 28px;
            bottom: 0;
            left: 0;
            margin-top: -1em;
            width: 229px;
            border-radius: 1px;
            z-index: -1;
          }
        }
        &::after {
          content: "";
          position: absolute;
          border-color: #f2e6ff;
          border-style: solid;
          border-width: 5.5px 0;
          height: 11px;
          bottom: 0;
          left: 0;
          margin-top: -1em;
          width: 229px;
          border-radius: 1px;
          z-index: -1;
        }
      }

      .float-info {
        display: none;
        position: absolute;
        width: 632px !important;
        height: 255px !important;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        top: -290px !important;
        left: 3% !important;
        text-align: left;
        padding: 32px 24px;
        &::after {
          content: "";
          position: absolute;
          width: 63px;
          height: 40.09px;
          background: #ffffff;
          box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.08);
          border-radius: 3px;
          left: 50% !important;
          top: 85% !important;
          transform: rotate(45deg);
        }
      }
    }
    .info-action {
      padding-left: 14px;
      margin-top: -10px;
    }

    .info-active {
      display: block !important;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    margin-bottom: 0 !important;

    .create-card-action {
      width: 100%;
      text-align: left;
      .text-action {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: ${electricPurple800};
        display: inline-flex;
      }
      .highlighted {
        position: relative;
        color: ${electricPurple800};
        display: flex;
        padding: 0 4px;
        p {
          margin-bottom: 0;
        }

        &:hover {
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            background-color: #f2e6ff;
            border-color: #f2e6ff;
            border-style: solid;
            border-width: 5.5px 0;
            height: 28px;
            bottom: 0;
            left: 0;
            margin-top: -1em;
            width: 229px;
            border-radius: 1px;
            z-index: -1;
          }
        }
        &::after {
          content: "";
          position: absolute;
          border-color: #f2e6ff;
          border-style: solid;
          border-width: 5.5px 0;
          height: 11px;
          bottom: 0;
          left: 0;
          margin-top: -1em;
          width: 229px;
          border-radius: 1px;
          z-index: -1;
        }
      }

      .float-info {
        display: none;
        position: absolute;
        width: 50vw;
        height: 255px;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        top: -185px !important;
        left: 44% !important;
        padding: 32px 24px;
        &::after {
          content: "";
          position: absolute;
          width: 63px;
          height: 40.09px;
          background: #ffffff;
          box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.08);
          border-radius: 3px;
          left: -3% !important;
          top: 70% !important;
          transform: rotate(45deg);
        }
      }
    }
    .info-action {
      padding-left: 14px;
      margin-top: -10px;
    }

    .info-active {
      display: block !important;
    }
  }
  padding-top: 48px;
  padding-bottom: 10px;
`;
