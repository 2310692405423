import { COUNTRIES } from "../shared/constants";

export const getCountry = () => {
  const currentCountry = process.env.REACT_APP_COUNTRY ?? COUNTRIES.CO.LABEL;
  return currentCountry;
};

export const isDefaultCountry = () => {
  return getCountry() === COUNTRIES.CO.LABEL;
};
