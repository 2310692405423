import React, { useState, useEffect } from "react";
import { BluePrintsWrapper } from "./style";
import GalleryModal from "components/gallery/galleryModal";

const BluePrints = (props) => {
  const [state, setState] = useState({ currentIndex: null, isOpen: false });
  const [blueprints, setBlueprints] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (blueprints) {
      if (props.blueprints != blueprints) {
        let imagesGallery = [];
        setBlueprints(props.blueprints);
        props.blueprints.map((blueprint) => {
          imagesGallery.push(blueprint.imagen);
        });
        setImages(imagesGallery);
      }
    }
    if (props.openBlueprint) {
      setState({ currentIndex: 0, isOpen: true });
      props.onClickBluePrints();
    }
  }, [props.blueprints, props.openBlueprint]);
  const findPrev = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setState((prevState) => ({
      currentIndex: prevState.currentIndex - 1,
    }));
  };

  const findNext = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setState((prevState) => ({
      currentIndex: prevState.currentIndex + 1,
    }));
  };
  const closeModal = () => {
    setState({ currentIndex: 0, isOpen: false });
  };
  function clickImagen(e) {
    e.persist();
    console.log(e);
    let index = parseInt(e.target.id);
    setState({ currentIndex: index, isOpen: true });
  }
  return (
    <BluePrintsWrapper ref={props.blueprintsRef}>
      <p className="title-description" tabIndex={-1}>
        PLANOS
      </p>
      <div className="gallery-blueprint">
        {blueprints.length > 1
          ? blueprints.map((blueprint, position) => {
              return (
                <div className="frame-blueprint">
                  <div className="blueprint" id={position}>
                    <img
                      alt="img"
                      src={blueprint.imagen}
                      width="256"
                      onClick={clickImagen}
                      height="283"
                      id={position}
                    />
                  </div>
                  <h6 className="title-blueprint">{position + 1}° PLANTA </h6>
                </div>
              );
            })
          : blueprints.map((blueprint, position) => {
              return (
                <div className="frame-blueprint-only">
                  <div
                    className="blueprint-only"
                    onClick={clickImagen}
                    id={position}
                  >
                    <img
                      alt="img"
                      src={blueprint.imagen}
                      width="256"
                      height="283"
                      onClick={clickImagen}
                      id={position}
                    />
                  </div>
                  <h6 className="title-blueprint">ÁREAS DEL APARTAMENTO </h6>
                </div>
              );
            })}
      </div>
      <GalleryModal
        closeModal={closeModal}
        findPrev={findPrev}
        findNext={findNext}
        images={images}
        currentIndex={state.currentIndex}
        isOpen={state.isOpen}
      />
    </BluePrintsWrapper>
  );
};

export default BluePrints;
