import React from 'react';
import { MarkerWrapper } from './style';

const Marker = ({ color, name, id }) => {
  return (
    <MarkerWrapper>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={name}
      />
      <div className="pulse" />
    </MarkerWrapper>
  );
};

export default Marker;
