import styled from "styled-components";
import {
  size,
  white,
  archiaBoldFamily,
  light_blue,
  black,
  purple,
  grey,
} from "StylesConstants";

export const LandingWrapperWrapper = styled.div`
  .landing-container {
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 24px 15px;
  }

  .container-header {
    background-color: ${light_blue};
  }

  .main-title {
    font-family: ${archiaBoldFamily};
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (min-width: ${size.tablet}) {
    .landing-container {
      display: grid;
      align-items: center;
      justify-items: center;
      margin-top: 32px;
    }

    .main-title {
      font-family: ${archiaBoldFamily};
      font-size: 40px;
      line-height: 56px;
      margin: 0px 25px;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .landing-container {
      max-width: 1200px;
      margin: auto;
      margin-top: 48px;
    }

    .main-title {
      font-family: ${archiaBoldFamily};
      font-size: 56px;
      line-height: 72px;
    }
  }
`;

export const LandingWrapper = styled.div`
  .cards-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 45px;
    margin: 56px 0px;
  }

  @media screen and (min-width: ${size.tablet}) {
    .cards-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px;
      margin: 56px 0px;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .cards-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 45px;
      margin: 56px 0px;
    }
  }
`;

export const CardWrapper = styled.div`
  height: 352px;
  width: 100%;
  border-radius: 40px;
  box-shadow: 1px 2px 8px rgba(35, 55, 65, 0.16);

  .custom-c {
    background-color: ${black};
  }
  .img-card {
    height: 235px;
    width: 100%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    object-fit: cover;
    justify-self: center;
  }

  .img-container {
    height: 235px;
  }
  .container-card {
    position: relative;
  }
  .visit-button {
    position: absolute;
    top: 20px;
    height: 30px;
    margin-left: 20px;
    background-color: ${white};
    font-size: 14px;
    font-weight: 600;
    padding: 2px 6px;
    color: ${black};
    border-radius: 7px;
    padding-top: 4px;
    cursor: pointer;
    opacity: 1;
    border-radius: 1px solid ${grey};
  }

  .text-container {
    margin: 24px;
  }

  .title {
    font-family: ${archiaBoldFamily};
    font-size: 14px;
    line-height: 16px;
  }

  .img-text {
    background: linear-gradient(
      180deg,
      rgba(45, 45, 45, 0) 50%,
      rgba(45, 45, 45, 0.72) 75%
    );
    height: 100px;
    position: relative;
    bottom: 100px;
  }

  .text-value {
    bottom: 8px;
    position: absolute;
    color: ${white};
    font-family: ${archiaBoldFamily};
    padding-left: 20px;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(45, 45, 45, 0.8);
  }

  @media screen and (min-width: ${size.tablet}) {
    height: 352px;
    width: 352px;
    border-radius: 40px;
    box-shadow: 1px 2px 8px rgba(35, 55, 65, 0.16);

    .img-card {
      height: 235px;
      width: 352px;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      object-fit: cover;
      justify-self: center;
    }
    .container-card {
      position: relative;
    }
    .visit-button {
      position: absolute;
      top: 20px;
      height: 30px;
      margin-left: 20px;
      background-color: ${white};
      font-size: 14px;
      font-weight: 600;
      padding: 2px 6px;
      color: ${black};
      border-radius: 7px;
      padding-top: 4px;
      cursor: pointer;
      opacity: 1;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .container-card {
      position: relative;
    }
    .modal-contact {
      width: 90%;
    }
    .visit-button {
      position: absolute;
      top: 20px;
      height: 30px;
      margin-left: 20px;
      background-color: ${white};
      font-size: 14px;
      font-weight: 600;
      padding: 2px 6px;
      color: ${black};
      border-radius: 7px;
      padding-top: 4px;
      cursor: pointer;
      opacity: 1;
    }
  }
`;

export const FormWrapper = styled.div`
  .form-contact {
    #Cerrar {
      margin-right: 10px;
    }
    .title {
      display: grid;
      justify-items: start;
      margin-top: 5%;
      margin-left: 5%;
      h1 {
        font-size: 42px;
        line-height: 82px;
        color: ${purple};
        font-weight: 700;
      }
    }
    .subtitle {
      display: grid;
      justify-items: start;
      margin-left: 5%;

      h3 {
        font-size: 22px;
        line-height: 32px;
        color: ${purple};
      }
    }

    .contact-form {
      display: grid;
      width: 100%;
      justify-items: start;
      margin-top: 5%;
    }
    #formFilter {
      width: 100%;
      display: grid;
      grid-template-areas:
        "name phone"
        "email email"
        "btn btn";
      grid-row-gap: 25px;
      .name {
        grid-area: name;
      }
      .phone {
        grid-area: phone;
      }
      .email {
        grid-area: email;
      }
      .message {
        grid-area: message;
      }
      .btn-container {
        grid-area: btn;
        justify-self: center;
      }
    }
  }
  @media screen and (min-width: ${size.tablet}) {
    .form-contact {
      .title {
        display: grid;
        justify-items: start;
        margin-top: 5%;
        margin-left: 5%;
        h1 {
          font-size: 55px;
          line-height: 82px;
          color: ${purple};
          font-weight: 700;
        }
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .form-contact {
      #Cerrar {
        margin-right: 10px;
      }
      .title {
        display: grid;
        justify-items: start;
        margin-top: 5%;
        margin-left: 5%;
        h1 {
          font-size: 68px;
          line-height: 82px;
          color: ${purple};
          font-weight: 700;
        }
      }
    }
  }
`;
