import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { SalesDataContainer } from "./style";
import GalleryNew from "../../components/GalleryNew/Gallery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import Loader from "react-loader-spinner";
import MapContainer from "../MapContainer/mapContainer";
import CreditCalculatorContainer from "../CreditCalculator/creditCalculatorContainer";
import { Modal, ModalBody } from "reactstrap";
import NearPlaces from "./sharedComponents/NearPlaces/NearPlaces";
import ActionsUsers from "./sharedComponents/ActionsUser/ActionsUsers";
import Description from "./sharedComponents/DescriptionContainer/Description";
import FeaturesShared from "./sharedComponents/Features/FeaturesShared";
import OptionSticky from "./OptionSticky";
import DetailsCard from "@habi/habi-react-components/dist/DetailsCard/DetailsCard";
import FormContact from "../LandingSales/FormContact";
import Helmet from "react-helmet";
import BluePrints from "./sharedComponents/BluePrints/BluePrints";
import { minWidthQueries } from "StylesConstants";
import { focusOption, handleScroll, showNearPlaces } from "./utils";
import { isDefaultCountry } from "utils/utils";

const SalesData = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const [id, setId] = useState(0);
  const [images, setImages] = useState([]);
  const [isSticky, setStickyMenu] = useState(false);
  const [stickyY, setStickyY] = useState();
  const [classOut, setClassOut] = useState(0);
  const [blueprints, setBlueprints] = useState([]);
  const [modal, setModal] = useState(false);
  const [bluePrintsOpen, setBluePrintsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const stickyMenu = useRef(null);
  const galleryRef = useRef(null);
  const descriptionRef = useRef(null);
  const detailsRef = useRef(null);
  const containerPrincipal = useRef(null);
  const featuresRef = useRef(null);
  const nearPlacesRef = useRef(null);
  const locationRef = useRef(null);
  const blueprintsRef = useRef(null);
  const calculatorRef = useRef(null);
  const billsRef = useRef(null);
  const calContainer = useRef(null);

  const URL_BUCKET_IMG = process.env.REACT_APP_BUCKET_IMG;

  const isDesktop = useMediaQuery(minWidthQueries.desktop);
  const suffixes = {
    mobile: "-414",
    desktop: "-765",
  };

  const toggle = () => setModal(!modal);
  const onClickBluePrints = () => setBluePrintsOpen(!bluePrintsOpen);
  let ogImgName = "";
  let ogImgExt = "";
  let ogImg = "";

  const showImage360 =
    state.property?.detalles_propiedad.property_moment_id === 2 &&
    state.property?.detalles_propiedad.inventory_type_id === 11
      ? null
      : state.property?.url_360;

  useEffect((e) => {
    if (containerPrincipal.current) {
      if (containerPrincipal.current.scrollTop == 0 && stickyY == undefined) {
        if (stickyMenu.current) {
          setStickyY(stickyMenu.current.offsetTop);
        }
      }
    }
    if (props.dataProperty) {
      setTitle(
        props.dataProperty.titulo ||
          props.dataProperty.detalles_propiedad.meta_title
      );
      setDescription(props.dataProperty.descripcion);
    }
  });

  const activeScroll = (e) => {
    e.stopPropagation();
    const containers = {
      stickyMenu,
      containerPrincipal,
      galleryRef,
      descriptionRef,
      classOut,
      detailsRef,
      featuresRef,
      nearPlacesRef,
      locationRef,
      blueprints,
      blueprintsRef,
      calculatorRef,
      billsRef,
      calContainer,
    };
    const functions = { setStickyMenu, setClassOut };
    const size = { isMobile, isTablet, isMobileOnly };
    handleScroll(containers, functions, size);
  };

  function activeFocusOption(e) {
    e.persist();
    e.stopPropagation();
    const containers = {
      galleryRef,
      descriptionRef,
      detailsRef,
      featuresRef,
      nearPlacesRef,
      locationRef,
      blueprints,
      blueprintsRef,
      calculatorRef,
    };
    focusOption(e, containers);
  }

  useEffect(() => {
    const orderData = async (data) => {
      return data.sort((a, b) => (a.orden > b.orden ? 1 : -1));
    };

    let pathname = window.location.pathname;
    setId(pathname.replace("/inmueble/", ""));
    if (props.dataProperty) {
      setState({ property: props.dataProperty });
    }

    if (props.dataProperty && props.dataProperty.images) {
      orderData(props.dataProperty.images);
      let imagesArray = [];
      for (let index = 0; index < props.dataProperty.images.length; index++) {
        const element = props.dataProperty.images[index];
        if (element.type_img === 1) {
          const imgName = element.url.split(".")[0];
          const imgExt = element.url.split(".")[1];
          const suffix = !isDesktop
            ? `${suffixes.mobile}`
            : `${suffixes.desktop}`;
          const imgUrl = `${imgName}${suffix}.${imgExt}`;
          imagesArray.push({
            url: `${URL_BUCKET_IMG}${imgUrl}`,
            title: element.title,
            description: element.description,
          });
        }
      }
      let blueprintsArray = [];
      props.dataProperty.images.map((imagen, position) => {
        if (imagen.type_img === 2) {
          blueprintsArray.push({ imagen: `${URL_BUCKET_IMG}${imagen.url}` });
        }
      });

      setImages(imagesArray);
      setBlueprints(blueprintsArray);
    }
  }, [props.dataProperty]);

  if (props.dataProperty?.images) {
    ogImgName = props.dataProperty?.images[0].url.split(".")[0];
    ogImgExt = props.dataProperty?.images[0].url.split(".")[1];
    ogImg = `${URL_BUCKET_IMG}${ogImgName}${suffixes.mobile}.${ogImgExt}`;
  }
  return (
    <div className="mi" id={id}>
      {state.property && (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>{title}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={ogImg} />
          <meta property="og:image:alt" content={title} />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <SalesDataContainer
        onScroll={activeScroll}
        id="salesdatacontainer"
        ref={containerPrincipal}
        data-testid="salesdatacontainer"
      >
        <Loader
          type="BallTriangle"
          color="#7C01FF"
          height={150}
          className="loader"
          width={150}
          visible={props.isLoading}
        />
        {state.property && !props.isLoading && (
          <div>
            {images.length > 0 && (
              <GalleryNew
                newRef={galleryRef}
                images={images}
                vendido={
                  state.property.detalles_propiedad.property_moment_id === 7
                    ? true
                    : false
                }
                url_360={state.property.url_360}
                menuOpen={isSticky}
                apto={state.property.titulo}
                barrio={state.property.detalles_propiedad.barriocomun}
                idApto={props.inmuebleId}
                redirectUrl={window.location.href}
              ></GalleryNew>
            )}
            <div className="sales-container container">
              <ActionsUsers
                img360={showImage360}
                blueprints={blueprints}
                onClickBluePrints={onClickBluePrints}
                conjunto={state.property.detalles_propiedad.conjunto_edificio}
                isPrivate={state.property.detalles_propiedad.is_private}
                telefono={state.property.detalles_propiedad.telefono}
              />
              <Description
                nid={state.property.nid}
                ciudad={state.property.detalles_propiedad.ciudad}
                title={state.property.titulo}
                upz={state.property.detalles_propiedad.zona_mediana}
                rooms={state.property.detalles_propiedad.num_habitaciones}
                bathrooms={state.property.detalles_propiedad.banos}
                parking={state.property.detalles_propiedad.garajes}
                area={state.property.detalles_propiedad.area}
                description={descriptionRef}
                price={state.property.detalles_propiedad.precio_venta}
                address={state.property.detalles_propiedad.direccion}
                des={state.property.descripcion}
                img360={showImage360}
                isHabiWeb={props.isHabiWeb}
                elevators={state.property.detalles_propiedad.num_ascensores}
              />
              <DetailsCard
                newReference={detailsRef}
                data={state.property.detalles_propiedad}
                isHabiWeb={false}
                area={"detailCard"}
                TranslateCurrencies={t("information.currency")}
                TranslateAdministration={t("information.administration")}
                TranslateElevatorPlural={t("information.elevator.plural")}
                TranslateRoomPlural={t("information.room.plural")}
                TranslateGarage={t("information.garage")}
                TranslateNeighborhood={t("information.neighborhood")}
                showLevel={isDefaultCountry()}
              />
              <div
                className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
                ref={stickyMenu}
              >
                {isSticky && (
                  <OptionSticky
                    onClickOption={activeFocusOption}
                    classOut={classOut}
                    planos={blueprints}
                    showBills={props.services.length > 0}
                    showNearPlaces={showNearPlaces(props.nearPlaces)}
                    showSimulator={
                      props.tasaLeasing !== 0 && props.tasaCredito !== 0
                    }
                    showFeatures={
                      state.property.caracteristicas_propiedad.length > 0
                    }
                  />
                )}
              </div>
              <CreditCalculatorContainer
                refCal={calContainer}
                referenceCalculator={calculatorRef}
                referenceGastos={billsRef}
                valorInmueble={state.property.detalles_propiedad.precio_venta}
                estrato={state.property.detalles_propiedad.estrato}
                adminPrice={state.property.detalles_propiedad.last_admin_price}
                tasaLeasing={props.tasaLeasing}
                tasaCredito={props.tasaCredito}
                services={props.services}
                showBills={props.services.length > 0}
              />
              {/* 
              {!isMobile &&
                state.property.detalles_propiedad.is_private == 0 && (
                  <DetailsDesktop
                    conjunto={
                      state.property.detalles_propiedad.conjunto_edificio
                    }
                    toggle={toggle}
                    correo={state.property.detalles_propiedad.correo}
                    telefono={state.property.detalles_propiedad.telefono}
                    direccion={state.property.detalles_propiedad.direccion}
                    isHabiWeb={props.isHabiWeb}
                  />
                )} */}

              <FeaturesShared
                featuresRef={featuresRef}
                properties={state.property.caracteristicas_propiedad}
              />
              <NearPlaces
                city={state.property.detalles_propiedad.ciudad}
                nearPlaces={props.nearPlaces}
                nearPlacesRef={nearPlacesRef}
              />
              <MapContainer
                reference={locationRef}
                isPrivate={state.property.detalles_propiedad.is_private}
                latitude={state.property.detalles_propiedad.latitud}
                longitude={state.property.detalles_propiedad.longitud}
                address={state.property.detalles_propiedad.direccion}
              />
              {blueprints.length > 0 && (
                <BluePrints
                  blueprints={blueprints}
                  blueprintsRef={blueprintsRef}
                  openBlueprint={bluePrintsOpen}
                  onClickBluePrints={onClickBluePrints}
                />
              )}
            </div>

            {/*    {state.property.detalles_propiedad.is_private == 0 && isSticky ? (
              <DetailsMobile
                conjunto={state.property.detalles_propiedad.conjunto_edificio}
                toggle={toggle}
                telefono={state.property.detalles_propiedad.telefono}
              />
            ) : (
              ""
            )} */}
            <Modal
              isOpen={modal}
              toggle={toggle}
              backdrop={true}
              className="modal-lg "
            >
              <ModalBody>
                <FormContact
                  proyecto={state.property.detalles_propiedad.conjunto_edificio}
                  toggle={toggle}
                />
              </ModalBody>
            </Modal>
          </div>
        )}
      </SalesDataContainer>
    </div>
  );
};

export default SalesData;
