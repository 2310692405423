import styled from "styled-components";
import { black, size } from "StylesConstants";

export const BluePrintsWrapper = styled.div`
  grid-area: planos;
  display: grid;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  padding-top: 24px;
  padding-bottom: 23px;

  .title-description {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 16px;
    color: ${black};
    margin-bottom: 16px;
  }

  .gallery-blueprint {
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-inline-box;
    width: 100%;
    padding-bottom: 7px;
    /* width */
    &::-webkit-scrollbar {
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-track-piece {
      background: #dfdfdf8c;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .frame-blueprint-only {
      text-align: center;
      height: 276px;
      width: 100%;
      margin: auto;

      .blueprint-only {
        img {
          width: 100%;
          height: 256px;
        }
      }
      .title-blueprint {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #2d2d2d;
        opacity: 0.9;
      }
    }

    .frame-blueprint {
      margin-right: 32px;
      text-align: center;
      height: 272px;
      width: 286px;
      .blueprint {
        img {
          width: 283px;
          height: 256px;
        }
      }
      h6 {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #2d2d2d;
        opacity: 0.9;
      }
    }
  }
  @media screen and (min-width: ${size.tablet}) {
    padding-bottom: 23px;
    border-top: none;
    border-bottom: 1px solid #cecece;
    .title-description {
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.08em;
      line-height: 16px;
      color: ${black};
      margin-bottom: 16px;
    }

    .gallery-blueprint {
      height: auto;
      overflow-x: auto;
      overflow-y: hidden;
      display: -webkit-inline-box;
      width: 100%;
      padding-bottom: 7px;
      /* width */
      &::-webkit-scrollbar {
        height: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-track-piece {
        background: #dfdfdf8c;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .frame-blueprint-only {
        text-align: center;
        height: 380px;
        width: 594px;
        margin: auto;

        .blueprint-only {
          img {
            width: 594px;
            height: 369px;
          }
        }
        .title-blueprint {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #2d2d2d;
          opacity: 0.9;
        }
      }
      .frame-blueprint {
        margin-right: 32px;
        text-align: center;
        height: 312px;
        width: 286px;

        .blueprint {
          img {
            width: 256px;
            height: 283px;
          }
        }
        h6 {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #2d2d2d;
          opacity: 0.9;
        }
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    border: none;
    .title-description {
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.08em;
      line-height: 16px;
      color: ${black};
      margin-bottom: 16px;
    }

    .gallery-blueprint {
      height: auto;
      overflow-x: auto;
      overflow-y: hidden;
      display: -webkit-inline-box;
      width: 100%;
      padding-bottom: 7px;
      /* width */
      &::-webkit-scrollbar {
        height: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-track-piece {
        background: #dfdfdf8c;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .frame-blueprint-only {
        text-align: center;
        height: 380px;
        width: 594px;
        margin: auto;

        .blueprint-only {
          img {
            width: 594px;
            height: 369px;
          }
        }
        .title-blueprint {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #2d2d2d;
          opacity: 0.9;
        }
      }

      .frame-blueprint {
        margin-right: 32px;
        text-align: center;
        height: 312px;
        width: 286px;
        .blueprint {
          img {
            width: 256px;
            height: 283px;
          }
        }
        .title-blueprint {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #2d2d2d;
          opacity: 0.9;
        }
      }
    }
  }
`;
