import styled from "styled-components";

export const GalleryMobileWrapper = styled.div`
  .header-mob-gal {
    height: 64px;
    background-color: #fff;
    display: grid;
    justify-content: left;
    align-items: center;
    padding: 12px 16px;
    width: 100%;
  }
  .distribution {
    width: 100vw;
    display: grid;
    background-color: #fff;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding: 8px;
    overflow-y: auto;

    .shared {
      height: 192px;
      img {
        height: 192px;
        width: 100%;
      }
    }

    .complete {
      display: grid;
      grid-column-start: 1;
      grid-column-end: 3;
      height: 192px;
      img {
        height: 192px;
        width: 100%;
      }
    }
  }
`;
