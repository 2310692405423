import React, { useState } from "react";
import { MapWrapper, Map } from "./style";
import MapHabi from "./mapHabi";
import comoLlegar from "static/assets/images/icons/comollegar.svg";

const MapContainer = (props) => {
  const [setAddressMarker] = useState("");

  const getAddressPin = (address, lat, lng) => {
    setAddressMarker(address);
  };

  let incremento = props.isPrivate == 0 ? 0 : 0.00185;

  return (
    <MapWrapper>
      <p className="title-description">
        UBICACIÓN {props.isPrivate == 0 ? "" : "APROXIMADA"}
      </p>
      <Map>
        <MapHabi
          reference={props.reference}
          centerMap={{
            lat: props.latitude + incremento,
            lng: props.longitude - incremento,
          }}
          getAddressPin={getAddressPin}
        />
      </Map>
      {props.isPrivate == 0 && (
        <div className="options-map">
          <a
            className="como-llegar"
            href={
              "https://www.google.com/maps/dir//" +
              props.latitude +
              "," +
              props.longitude
            }
            target="_blank"
          >
            <span className="title-map-op">
              Cómo llegar
              <img src={comoLlegar} />
            </span>
          </a>
        </div>
      )}
    </MapWrapper>
  );
};

export default MapContainer;
