import styled from "styled-components";
export const ServiceLabelWrapper = styled.section`
  border: 1px solid #eaeaeb;
  border-radius: 8px;
  display: grid;
  grid-template: 1fr/ 0.2fr 0.8fr;
  font-size: 12px;
  line-height: 16px;
  .label-logo-container {
    justify-self: end;
  }
  .label-title {
    color: #6d6970;
    text-align: left;
    padding-left: 10px;
    p {
      margin-top: 5px;
      margin-bottom: 3px;
    }
  }
  .label-charge {
    font-size: 17px;
    line-height: 28px;
    font-weight: 600;
    p {
      margin-bottom: 10px;
    }
  }
  .label-logo {
    margin-top: 20%;
  }
`;
