import React, { useState, useEffect, useRef } from "react";
import { GalleryWrapper, GroupPhotos, Photo, Gallery } from "./style";
import { isTablet, isMobileOnly } from "react-device-detect";
import DetailGallery from "./DetailGallery";
import ImageGallery from "react-image-gallery";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import ModalShare from "../ShareModalSocialMedia/shareModal";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import Check from "./assets/img/icons/Check.svg";
import GalleryIcon from "./assets/img/icons/GalleryIcon.svg";
import GalleryMobile from "./mobile/GalleryMobile";
import { InfoBannerGallery } from "./style";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import { coral, white } from "StylesConstants";

const GalleryNew = (props) => {
  const [principalImage, setPrincipalImage] = useState({ url: "", order: 1 });
  const [arrayRows, setArrayRows] = useState([]);
  const [sizeRows, setSizeRows] = useState(2);
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [dimensionColumns, setDimensionColumns] = useState("1fr 1fr");
  const [showBullets, setShowBullets] = useState(true);
  const [openMobileGallery, setOpenMobileGallery] = useState(false);
  const galeriaMobile = useRef(null);
  const [modal, setModal] = useState(false);
  const share = useRef(null);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (props.images) {
      let imagesNew = [];
      props.images.map((imagen, position) => {
        if (position === 0) {
          setPrincipalImage({
            url: imagen.url,
            order: position + 1,
            title: imagen.title,
            description: imagen.description,
          });
        } else {
          imagesNew.push({
            url: imagen.url,
            order: position + 1,
            title: imagen.title,
            description: imagen.description,
          });
        }
      });
      setArrayRows([]);

      if (isTablet) {
        switch (imagesNew.length) {
          case 0:
            setSizeRows(1);
            setDimensionColumns("1fr");
            break;
          case 1:
            setSizeRows(1);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 2:
            setSizeRows(2);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          default:
            setSizeRows(3);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                ],
              },
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("60% 40%");
            break;
        }
      } else {
        switch (imagesNew.length) {
          case 0:
            setSizeRows(1);
            setDimensionColumns("1fr");
            break;
          case 1:
            setSizeRows(1);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 2:
            setSizeRows(2);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 3:
            setSizeRows(2);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
              {
                columns: 2,
                photos: [
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 4:
            setSizeRows(2);
            setArrayRows([
              {
                columns: 2,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                ],
              },
              {
                columns: 2,
                photos: [
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                  {
                    url: imagesNew[3].url,
                    index: imagesNew[3].order,
                    title: imagesNew[3].title,
                    description: imagesNew[3].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 5:
            setSizeRows(2);
            setArrayRows([
              {
                columns: 2,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                  {
                    url: imagesNew[3].url,
                    index: imagesNew[3].order,
                    title: imagesNew[3].title,
                    description: imagesNew[3].description,
                  },
                  {
                    url: imagesNew[4].url,
                    index: imagesNew[4].order,
                    title: imagesNew[4].title,
                    description: imagesNew[4].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 6:
            setSizeRows(2);
            setArrayRows([
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[3].url,
                    index: imagesNew[3].order,
                    title: imagesNew[3].title,
                    description: imagesNew[3].description,
                  },
                  {
                    url: imagesNew[4].url,
                    index: imagesNew[4].order,
                    title: imagesNew[4].title,
                    description: imagesNew[4].description,
                  },
                  {
                    url: imagesNew[5].url,
                    index: imagesNew[5].order,
                    title: imagesNew[5].title,
                    description: imagesNew[5].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 7:
            setSizeRows(3);
            setArrayRows([
              {
                columns: 1,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                  {
                    url: imagesNew[3].url,
                    index: imagesNew[3].order,
                    title: imagesNew[3].title,
                    description: imagesNew[3].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[4].url,
                    index: imagesNew[4].order,
                    title: imagesNew[4].title,
                    description: imagesNew[4].description,
                  },
                  {
                    url: imagesNew[5].url,
                    index: imagesNew[5].order,
                    title: imagesNew[5].title,
                    description: imagesNew[5].description,
                  },
                  {
                    url: imagesNew[6].url,
                    index: imagesNew[6].order,
                    title: imagesNew[6].title,
                    description: imagesNew[6].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          case 8:
            setSizeRows(3);
            setArrayRows([
              {
                columns: 2,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                  {
                    url: imagesNew[3].url,
                    index: imagesNew[3].order,
                    title: imagesNew[3].title,
                    description: imagesNew[3].description,
                  },
                  {
                    url: imagesNew[4].url,
                    index: imagesNew[4].order,
                    title: imagesNew[4].title,
                    description: imagesNew[4].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[5].url,
                    index: imagesNew[5].order,
                    title: imagesNew[5].title,
                    description: imagesNew[5].description,
                  },
                  {
                    url: imagesNew[6].url,
                    index: imagesNew[6].order,
                    title: imagesNew[6].title,
                    description: imagesNew[6].description,
                  },
                  {
                    url: imagesNew[7].url,
                    index: imagesNew[7].order,
                    title: imagesNew[7].title,
                    description: imagesNew[7].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
          default:
            setSizeRows(3);
            setArrayRows([
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[0].url,
                    index: imagesNew[0].order,
                    title: imagesNew[0].title,
                    description: imagesNew[0].description,
                  },
                  {
                    url: imagesNew[1].url,
                    index: imagesNew[1].order,
                    title: imagesNew[1].title,
                    description: imagesNew[1].description,
                  },
                  {
                    url: imagesNew[2].url,
                    index: imagesNew[2].order,
                    title: imagesNew[2].title,
                    description: imagesNew[2].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[3].url,
                    index: imagesNew[3].order,
                    title: imagesNew[3].title,
                    description: imagesNew[3].description,
                  },
                  {
                    url: imagesNew[4].url,
                    index: imagesNew[4].order,
                    title: imagesNew[4].title,
                    description: imagesNew[4].description,
                  },
                  {
                    url: imagesNew[5].url,
                    index: imagesNew[5].order,
                    title: imagesNew[5].title,
                    description: imagesNew[5].description,
                  },
                ],
              },
              {
                columns: 3,
                photos: [
                  {
                    url: imagesNew[6].url,
                    index: imagesNew[6].order,
                    title: imagesNew[6].title,
                    description: imagesNew[6].description,
                  },
                  {
                    url: imagesNew[7].url,
                    index: imagesNew[7].order,
                    title: imagesNew[7].title,
                    description: imagesNew[7].description,
                  },
                  {
                    url: imagesNew[8].url,
                    index: imagesNew[8].order,
                    title: imagesNew[8].title,
                    description: imagesNew[8].description,
                  },
                ],
              },
            ]);
            setDimensionColumns("1fr 1fr");
            break;
        }
      }
    }
  }, [props.images]);

  const launchFullscreen = (e) => {
    e.persist();
    setIndex(e["target"]["attributes"][0]["value"]);
    setOpenModal(!openModal);
  };

  const launchAllImages = (e) => {
    e.persist();
    setIndex(0);
    setOpenModal(!openModal);
  };

  const closeModal = (e) => {
    setOpenModal(false);
    setShowBullets(true);
  };

  const clickImagen = (index) => {
    setOpenModal(true);
    setIndex(index);
  };
  const closeMobileGallery = () => {
    setOpenMobileGallery(false);
  };

  let imagesMobile = [];
  props.images.map((image) => {
    imagesMobile.push({ original: image.url, thumbnail: image.url });
  });

  return (
    <Gallery ref={props.newRef}>
      {!isMobileOnly && (
        <GalleryWrapper
          rows={sizeRows}
          columns={dimensionColumns}
          urlImage={principalImage.url}
        >
          <div
            data-index={principalImage.order}
            className="principal-photo"
            onClick={launchFullscreen}
          ></div>
          {arrayRows.length > 0 && (
            <div className="secondary-photos">
              {arrayRows &&
                arrayRows.map((row, position) => {
                  let photos = row.photos.map((photo, p) => {
                    return (
                      <Photo
                        image={photo.url}
                        onClick={launchFullscreen}
                        data-index={photo.index}
                        key={p}
                      ></Photo>
                    );
                  });
                  return (
                    <GroupPhotos photosColumns={row.columns} key={position}>
                      {photos}
                    </GroupPhotos>
                  );
                })}
            </div>
          )}
        </GalleryWrapper>
      )}
      <DetailGallery
        open={openModal}
        images={props.images}
        closeModal={closeModal}
        index={index}
        apto={props.apto}
        barrio={props.barrio}
        idApto={props.idApto}
      />

      {isMobileOnly ? (
        <>
          <ImageGallery
            ref={galeriaMobile}
            items={imagesMobile}
            showThumbnails={false}
            onThumbnailClick={() => {
              setOpenMobileGallery(true);
            }}
            onClick={() => {
              setOpenMobileGallery(true);
            }}
            showBullets={showBullets}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
          />
          {props.availableFor === "en_tramite" && (
            <InfoBannerGallery bgColor={coral} textColor={white}>
              <WatchLaterRoundedIcon className="flag-icon" />
              EN TRÁMITE
            </InfoBannerGallery>
          )}
          <a
            className={!props.menuOpen ? "back" : "back-withoutMenu"}
            href={props.redirectUrl || "/venta-apartamentos"}
            ref={share}
          >
            <KeyboardBackspaceRoundedIcon className="icon" />
          </a>
          <div
            className={!props.menuOpen ? "share" : "share-withoutMenu"}
            onClick={toggle}
            ref={share}
          >
            <ReplyRoundedIcon className="icon" />{" "}
            <p className="share-name">Compartir</p>
          </div>
        </>
      ) : (
        <>
          {props.availableFor === "en_tramite" && (
            <InfoBannerGallery bgColor={coral} textColor={white}>
              <WatchLaterRoundedIcon className="flag-icon" />
              EN TRÁMITE
            </InfoBannerGallery>
          )}
          <div className={"share"} onClick={toggle} ref={share}>
            <ReplyRoundedIcon className="icon" />{" "}
            <p className="share-name">Compartir</p>
          </div>
          {props.images.length > 10 && (
            <div className={"photos-bt"} onClick={launchAllImages}>
              <img src={GalleryIcon} className="icon" alt={"More photos"} />
              <p className="share-name">
                Ver {props.images.length}{" "}
                {props.images.length != 1 ? "fotos" : "foto"}
              </p>
            </div>
          )}
        </>
      )}

      {props.vendido && (
        <div className="status-aparment">
          <img src={Check} alt={"vendido"} /> <span>VENDIDO</span>
        </div>
      )}
      {openMobileGallery && (
        <GalleryMobile
          images={props.images}
          apto={props.apto}
          barrio={props.barrio}
          idApto={props.idApto}
          clickImagen={clickImagen}
          closeGallery={closeMobileGallery}
        />
      )}

      <ModalShare
        isOpen={modal}
        backdrop={true}
        url={window.location.href}
        toggle={toggle}
      />
    </Gallery>
  );
};

export default GalleryNew;
