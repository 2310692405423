import React from "react";
import { InputWrapper } from "./style";
import { Field, ErrorMessage } from "formik";

const TextInput = ({
  name,
  errors,
  id,
  label,
  className,
  touched,
  ...props
}) => (
  <InputWrapper>
    <div className={`form-group ${className}`}>
      {label && (
        <label htmlFor={name} className="input-label">
          {label} {props.required ? "*" : ""}
        </label>
      )}
      <Field
        id={id}
        name={name}
        autoComplete={false ? "off" : "on"}
        placeholder={props.placeholder}
        disabled={props.disabled ? props.disabled : false}
        type={props.type ? props.type : "text"}
        className={`form-control ${
          errors[name] && touched[name] ? " is-invalid" : ""
        }`}
      />
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  </InputWrapper>
);

export default TextInput;
