import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { DescriptionContainer } from "../../style";
import verificationBadge from "static/assets/images/icons/sello_garantia_habi.svg";
import { isMobileOnly, isMobile } from "react-device-detect";
import Recorrido from "../Recorrido/Recorrido";
import TitleCard from "@habi/habi-react-components/dist/TitleCard/titleCard";
import { isDefaultCountry } from "utils/utils";

const Description = ({ ciudad, ...props }) => {
  const { t } = useTranslation();
  const [title, setTitulo] = useState("");
  const [upz, setUpz] = useState("");
  const [img360, setImg360] = useState("");
  const [rooms, setRooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [parking, setParking] = useState("");
  const [area, setArea] = useState("");
  const [elevators, setElevators] = useState("");
  const [des, setDes] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    setDes(props.des);
    setImg360(props.img360);
    setTitulo(props.title);
    setUpz(props.upz);
    setRooms(props.rooms);
    setBathrooms(props.bathrooms);
    setParking(props.parking);
    setArea(props.area);
    setElevators(props.elevators);
    setPrice(props.price);
  }, [
    props.img360,
    props.title,
    props.upz,
    props.rooms,
    props.bathrooms,
    props.parking,
    props.area,
    props.elevators,
    props.description,
    props.price,
    props.des,
  ]);

  const titleUpperCase = () => {
    if (!!ciudad) {
      if (ciudad === "bogota") {
        return "Bogotá";
      } else if (ciudad === "medellin") {
        return "Medellín";
      } else {
        return ciudad.charAt(0).toUpperCase() + ciudad.slice(1);
      }
    }
    return "";
  };
  return (
    <DescriptionContainer ref={props.description}>
      <TitleCard
        nid={props.nid}
        isMx={!isDefaultCountry()}
        ciudad={titleUpperCase()}
        upz={upz}
        title={title}
        rooms={rooms}
        bathrooms={bathrooms}
        parking={parking}
        area={area}
        elevators={elevators}
        price={price}
        TranslateCurrencies={t("information.currency")}
        TranslateParkingPlural={t("information.parking.plural")}
        TranslateParkingSingular={t("information.parking.singular")}
        TranslateElevatorPlural={t("information.elevator.plural")}
        TranslateElevatorSingular={t("information.elevator.singular")}
        TranslateRoomPlural={t("information.room.plural")}
        TranslateRoomSingular={t("information.room.singular")}
      />
      {isMobile && props.isHabiWeb && (
        <div className="verification-badge">
          <div className="img-bd">
            <img src={verificationBadge} alt="verification-habi" />
          </div>

          <div className="description-area">
            <p className="description-bd">
              Nuestros apartamentos cuentan con un estudio de títulos que
              garantizan una transacción transparente y sin líos judiciales.
            </p>
            {!isMobileOnly && (
              <p className="description-bd">
                La propiedad que recibes está como nueva, sus acabados, pisos,
                paredes e instalaciones de servicios públicos se encuentran en
                estado óptimo para su uso inmediato.
              </p>
            )}
          </div>
        </div>
      )}

      <p className="title-description" tabIndex={-1}>
        DESCRIPCIÓN
      </p>
      <div className="description">
        {des}
        <Recorrido url_360={img360} />
      </div>
    </DescriptionContainer>
  );
};

export default Description;
