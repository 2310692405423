// Grays
export const gray2 = "#565555";
export const gray3 = "#857c81";
export const gray4 = "#b9b9b9";
export const gray5 = "#e0dddd";

// Colors
export const primaryGreen = "#7C01FF";
export const primaryGray = "#E3E4E6";
export const secondaryTextBlue = "#2D2D2D";
export const borderGray = "rgba(35, 55, 65, 0.24)";
export const textBlue = "#233741";
export const textGray = "#E4E7E8";
export const textGray2 = "#808A8F";
export const error = "#FF4B4B";
export const disabled = "#E4E7E8";
// Fonts
export const fontFamily = "archia";
export const fontSize = "15px";
export const secondaryFamily = "archia";
export const subtitle = "#808a8f";
export const primary1 = "#6ca583";
export const accent1 = "#9b8dab";
//size
export const size = {
  tablet: "744px",
  desktop: "1128px"
};

export const minWidthQueries = {
  desktop: { query: `(min-width: ${size.desktop})` },
};

//NEW_COLORS
export const dark_purple = "#5701B3";
export const purple = "#7C01FF";
export const light_purple = "#AAA7FC";
export const coral = "#FF5958";
export const aquamarine = "#00F2C3";
export const blue = "#32C1FF";
export const light_blue = "#B2E7FF";
export const green = "#C3FAAB";
export const light_green = "#E2FFD7";
export const white = "#FFFFFF";
export const black = "#2D2D2D";
export const dark_grey = "#B8B8B8";
export const light_grey = "#F9FAFC";
export const grey = "#DDDDDD";
export const grey_tab_light = "#F2E5F5";


//NEW COLOR PALETTE

//PURPLE PALETTE
export const electricPurple900 = "#190033";
export const electricPurple800 = "#320066";
export const electricPurple700 = "#4A0199";
export const electricPurple600 = "#6301CC";
export const electricPurple500 = "#7C01FF";
export const electricPurple400 = "#9634FF";
export const electricPurple300 = "#B067FF";
export const electricPurple200 = "#CB99FF";
export const electricPurple100 = "#E5CCFF";
export const electricPurple000 = "#F2E5F5";

//SKY BLUE PALETTE
export const skyBlue900 = "#0A2733";
export const skyBlue800 = "#144D66";
export const skyBlue700 = "#1E7499";
export const skyBlue600 = "#289ACC";
export const skyBlue500 = "#32C1FF";
export const skyBlue400 = "#5BCDFF";
export const skyBlue300 = "#84DAFF";
export const skyBlue200 = "#ADE6FF";
export const skyBlue100 = "#D6F3FF";
export const skyBlue000 = "#EBF9FF";

//BOGOTA BLUE PALETTE
export const bogotaBlue900 = "#242E33";
export const bogotaBlue800 = "#475C66";
export const bogotaBlue700 = "#6B8B99";
export const bogotaBlue600 = "#8EB9CC";
export const bogotaBlue500 = "#B2E7FF";
export const bogotaBlue400 = "#C1ECFF";
export const bogotaBlue300 = "#D1F1FF";
export const bogotaBlue200 = "#E0F5FF";
export const bogotaBlue100 = "#F0FAFF";
export const bogotaBlue000 = "#F7FDFF";

//TURQUOISE PALETTE
export const brightTurquoise900 = "#003027";
export const brightTurquoise800 = "#00614E";
export const brightTurquoise700 = "#009175";
export const brightTurquoise600 = "#00C29C";
export const brightTurquoise500 = "#00F2C3";
export const brightTurquoise400 = "#33F5CF";
export const brightTurquoise300 = "#66F7DB";
export const brightTurquoise200 = "#99FAE7";
export const brightTurquoise100 = "#CCFCF3";
export const brightTurquoise000 = "#E6FEF9";

//GRAY PALETTE = BLACK
export const scarpaGray900 = "#000000";
export const scarpaGray800 = "#252129";
export const scarpaGray700 = "#2E2933";
export const scarpaGray600 = "#433E47";
export const scarpaGray500 = "#6D6970";
export const scarpaGray400 = "#979499";
export const scarpaGray300 = "#C0BFC2";
export const scarpaGray200 = "#D3D2D4";
export const scarpaGray100 = "#E6E5E7";
export const scarpaGray000 = "#FCFCFC";


// Font-family
export const archiaRegular = "archia";
export const archiaBoldFamily = "archiaBold";
