import React, { useState, useRef, useEffect } from "react";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import { isMobile, isTablet, isMobileOnly } from "react-device-detect";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import ModalShare from "../sharedComponents/ShareModalSocialMedia/ModalShare";
import { DATA_MENU, filterMenu } from "./utils";

const OptionSticky = (props) => {
  const reference = useRef(null);

  const [modal, setModal] = useState(false);
  const [activeLeft, setActiveLeft] = useState(false);
  const [activeRight, setActiveRight] = useState(true);
  const [menu, setMenu] = useState([]);
  const [menuTablet, setMenuTablet] = useState([]);

  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (props.planos) {
      let menuList = DATA_MENU;
      if (!props.showBills) menuList = filterMenu(menuList, 7);
      if (!props.showNearPlaces) menuList = filterMenu(menuList, 4);
      if (!props.showSimulator) menuList = filterMenu(menuList, 6);
      if (!props.showFeatures) menuList = filterMenu(menuList, 3);

      let menuTabletList = filterMenu(menuList, 7);
      if (props.planos.length === 0) {
        menuList = filterMenu(menuList, 8);
        menuTabletList = filterMenu(menuTabletList, 8);
        setMenu(menuList);
        setMenuTablet(menuTabletList);
      } else {
        setMenu(menuList);
        setMenuTablet(menuTabletList);
      }
    }
    if (props.classOut) {
      let menuN = isTablet ? menuTablet : menu;
      if (menuN.length > 0) {
        menuN.map((opcion, p) => {
          if (opcion.id == props.classOut) {
            let opcionname =
              isTablet && opcion.id == 6 ? "cal&gas" : opcion.name;
            const element = document.getElementById(
              opcionname == "Simulador" && isTablet ? "cal&gas" : opcionname
            );
            if (element.offsetLeft >= reference.current.offsetWidth) {
              setActiveLeft(true);
              setActiveRight(false);
            } else {
              setActiveLeft(false);
              setActiveRight(true);
            }
            reference.current.scrollTo({
              left: element.offsetLeft - 120,
            });
          }
        });
      }
    }
  }, [props.planos, props.classOut]);
  function scrollLeft(e) {
    e.persist();
    reference.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
    setActiveLeft(false);
    setActiveRight(true);
  }
  function scrollRight(e) {
    e.persist();
    reference.current.scrollTo({
      left: reference.current.getBoundingClientRect().right,
      behavior: "smooth",
    });
    setActiveLeft(true);
    setActiveRight(false);
  }

  function descriptionClick(e) {
    props.onClickOption(e);
    reference.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
    setActiveLeft(false);
    setActiveRight(true);
  }
  function clickOption(name, e) {
    props.onClickOption(e);
    const idElement = document.getElementById(name);
    if (idElement.offsetLeft >= reference.current.offsetWidth) {
      setActiveLeft(true);
      setActiveRight(false);
    } else {
      setActiveLeft(false);
      setActiveRight(true);
    }
    reference.current.scrollTo({
      left: idElement.offsetLeft - 50,
      behavior: "smooth",
    });
  }

  let classLeft = activeLeft ? "" : "oculto";
  let classRight = activeRight ? "" : "oculto";

  let menuN = isTablet ? menuTablet : menu;
  return (
    <div className="sticky-inner">
      <div className="sticky-inner container">
        <div className="menu" id="menu" ref={reference}>
          {isMobileOnly && (
            <div className={"left-icon " + classLeft} onClick={scrollLeft}>
              <NavigateBeforeRoundedIcon />
            </div>
          )}
          {menuN.map((opcion, p) => {
            if (!isTablet) {
              let classOut = props.classOut == opcion.id ? "active" : "";
              return (
                <div
                  className={"item-sticky " + classOut}
                  id={opcion.name}
                  key={opcion.id}
                  onClick={
                    opcion.id == 1
                      ? descriptionClick.bind(this)
                      : clickOption.bind(this, opcion.name)
                  }
                >
                  {opcion.name}
                </div>
              );
            } else if (isTablet && opcion.id <= 8 && opcion.id != 7) {
              const clase = props.classOut == 7 ? 6 : props.classOut;
              const classOut = clase == opcion.id ? "active" : "";

              return (
                <div
                  className={"item-sticky " + classOut}
                  id={opcion.idN ? opcion.idN : opcion.name}
                  key={opcion.id}
                  onClick={props.onClickOption.bind(this)}
                >
                  {opcion.name}
                </div>
              );
            }
          })}

          {isMobileOnly && (
            <div className={"right-icon " + classRight} onClick={scrollRight}>
              <NavigateNextRoundedIcon />
            </div>
          )}
        </div>
        {!isMobile && (
          <div className="options-share" onClick={toggle}>
            <div className={"item-sticky "}>
              {" "}
              <ReplyRoundedIcon className="icon" />
              Compartir
            </div>
          </div>
        )}
        {isTablet && (
          <div className="options-share" onClick={toggle}>
            <div className={"item-sticky "}>
              <ReplyRoundedIcon className="icon" />
            </div>
          </div>
        )}
      </div>
      <ModalShare
        isOpen={modal}
        backdrop={true}
        url={window.location.href}
        toggle={toggle}
      />
    </div>
  );
};

export default OptionSticky;
