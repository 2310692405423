import React from "react";

import LocalMallIcon from "@material-ui/icons/LocalMall";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NatureIcon from "@material-ui/icons/Nature";
import SchoolIcon from "@material-ui/icons/School";
import FlightIcon from "@material-ui/icons/Flight";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LocalPoliceIcon from "@material-ui/icons/Policy";
import DirectionsSubwayIcon from "@material-ui/icons/DirectionsSubway";
import DirectionsTransitIcon from "@material-ui/icons/DirectionsTransit";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import MuseumIcon from "@material-ui/icons/Museum";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TheatersIcon from "@material-ui/icons/Theaters";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";

export const handleScroll = (containers, functions, size) => {
  const {
    stickyMenu,
    containerPrincipal,
    galleryRef,
    descriptionRef,
    classOut,
    detailsRef,
    featuresRef,
    nearPlacesRef,
    locationRef,
    blueprints,
    blueprintsRef,
    calculatorRef,
    billsRef,
    calContainer,
  } = containers;
  const { setStickyMenu, setClassOut } = functions;
  const { isMobile, isTablet, isMobileOnly } = size;
  if (stickyMenu.current) {
    let topScroll = containerPrincipal.current.scrollTop;
    if (!galleryRef.current) return;
    setStickyMenu(topScroll >= galleryRef.current.scrollHeight);
    let lastMeasurement =
      galleryRef.current.scrollHeight + descriptionRef.current.scrollHeight;
    if (!isMobile) {
      let previousLink = galleryRef.current.scrollHeight;
      if (
        topScroll >= galleryRef.current.scrollHeight &&
        topScroll <=
          galleryRef.current.scrollHeight +
            descriptionRef.current.scrollHeight -
            1 &&
        classOut !== 1
      ) {
        setClassOut(1);
      }
      previousLink = previousLink + descriptionRef.current.scrollHeight;
      if (
        topScroll >= previousLink &&
        topScroll < previousLink + detailsRef.current.scrollHeight &&
        classOut !== 2
      ) {
        setClassOut(2);
      }
      previousLink = previousLink + detailsRef.current.scrollHeight;

      if (
        topScroll >= previousLink &&
        topScroll < previousLink + featuresRef.current.scrollHeight &&
        classOut !== 3
      ) {
        setClassOut(3);
      }
      previousLink = previousLink + featuresRef.current.scrollHeight;

      if (
        topScroll >= previousLink &&
        topScroll < previousLink + nearPlacesRef.current.scrollHeight &&
        classOut !== 4
      ) {
        setClassOut(4);
      }
      previousLink = previousLink + nearPlacesRef.current.scrollHeight;

      if (
        topScroll >= previousLink &&
        topScroll < previousLink + locationRef.current.scrollHeight &&
        classOut !== 5
      ) {
        setClassOut(5);
      }
      previousLink = previousLink + locationRef.current.scrollHeight;
      if (blueprints.length > 0) {
        if (
          topScroll >= previousLink &&
          topScroll < previousLink + blueprintsRef.current.scrollHeight - 1 &&
          classOut !== 8
        ) {
          setClassOut(8);
        }
        previousLink = previousLink + blueprintsRef.current.scrollHeight;
      }
      if (
        topScroll >= previousLink &&
        topScroll < previousLink + (calculatorRef.current.scrollHeight - 199) &&
        classOut !== 6
      ) {
        setClassOut(6);
      }
      previousLink = previousLink + (calculatorRef.current.scrollHeight - 200);
      if (topScroll >= previousLink && classOut !== 7) {
        setClassOut(7);
      }
    } else {
      if (
        topScroll >= galleryRef.current.scrollHeight &&
        topScroll <=
          galleryRef.current.scrollHeight +
            descriptionRef.current.scrollHeight -
            5 &&
        classOut !== 1
      ) {
        setClassOut(1);
      }
      if (
        topScroll >= lastMeasurement + 1 &&
        topScroll < lastMeasurement + featuresRef.current.scrollHeight - 1 &&
        classOut !== 2
      ) {
        setClassOut(2);
      }

      lastMeasurement = lastMeasurement + featuresRef.current.scrollHeight;
      if (
        topScroll >= lastMeasurement &&
        topScroll < lastMeasurement + nearPlacesRef.current.scrollHeight - 72 &&
        classOut !== 3
      ) {
        setClassOut(3);
      }
      lastMeasurement =
        lastMeasurement + nearPlacesRef.current.scrollHeight - 73;
      if (
        topScroll >= lastMeasurement &&
        topScroll < lastMeasurement + locationRef.current.scrollHeight - 1 &&
        classOut !== 4
      ) {
        setClassOut(4);
      }
    }
    if (!isTablet) {
      if (isMobileOnly) {
        lastMeasurement = lastMeasurement + locationRef.current.scrollHeight;

        if (blueprints.length > 0) {
          if (
            topScroll >= lastMeasurement &&
            topScroll <
              lastMeasurement + blueprintsRef.current.scrollHeight - 1 &&
            classOut !== 5
          ) {
            setClassOut(5);
          }
          lastMeasurement =
            lastMeasurement + blueprintsRef.current.scrollHeight;
          if (
            topScroll >= lastMeasurement &&
            topScroll <
              lastMeasurement + calculatorRef.current.scrollHeight - 1 &&
            classOut !== 8
          ) {
            setClassOut(8);
          }
        } else {
          if (
            topScroll >= lastMeasurement &&
            topScroll <
              lastMeasurement + calculatorRef.current.scrollHeight - 1 &&
            classOut !== 5
          ) {
            setClassOut(5);
          }
        }

        lastMeasurement = lastMeasurement + calculatorRef.current.scrollHeight;
        if (
          topScroll >= lastMeasurement &&
          topScroll < lastMeasurement + billsRef.current.scrollHeight / 2 - 1 &&
          classOut !== 6
        ) {
          setClassOut(6);
        }

        lastMeasurement = lastMeasurement + billsRef.current.scrollHeight / 2;
        if (topScroll >= lastMeasurement && classOut !== 7) {
          setClassOut(7);
        }
      }
    } else {
      lastMeasurement = lastMeasurement + locationRef.current.scrollHeight;
      if (blueprints.length > 0) {
        if (
          topScroll >= lastMeasurement &&
          topScroll <
            lastMeasurement + blueprintsRef.current.scrollHeight / 2 - 1 &&
          classOut !== 5
        ) {
          setClassOut(5);
        }
        lastMeasurement =
          lastMeasurement + blueprintsRef.current.scrollHeight + 1;
        if (
          topScroll >= lastMeasurement &&
          topScroll <
            lastMeasurement + calContainer.current.scrollHeight / 2 - 1 &&
          classOut !== 8
        ) {
          setClassOut(8);
        }
      } else {
        if (
          topScroll >= lastMeasurement &&
          topScroll <
            lastMeasurement + calContainer.current.scrollHeight / 2 - 1 &&
          classOut !== 5
        ) {
          setClassOut(5);
        }
      }

      lastMeasurement = lastMeasurement + calContainer.current.scrollHeight / 2;
      if (topScroll >= lastMeasurement && classOut !== 6) {
        setClassOut(6);
      }
    }
  }
};

export function focusOption(e, containers) {
  const {
    galleryRef,
    descriptionRef,
    detailsRef,
    featuresRef,
    nearPlacesRef,
    locationRef,
    blueprints,
    blueprintsRef,
    calculatorRef,
  } = containers;

  let salesDiv = document.getElementById("salesdatacontainer");
  let previousSection = galleryRef.current.scrollHeight;
  if (e.target.id === "Descripción") {
    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });

    descriptionRef.current.focus();
  }

  if (e.target.id === "Detalles") {
    previousSection =
      galleryRef.current.scrollHeight + descriptionRef.current.scrollHeight + 5;
    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
    detailsRef.current.focus();
  }
  if (e.target.id === "Características") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30;
    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
    featuresRef.current.focus();
  }

  if (e.target.id === "Lugares") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30 +
      featuresRef.current.scrollHeight +
      30;

    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });

    nearPlacesRef.current.focus();
  }

  if (e.target.id === "Ubicación") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30 +
      featuresRef.current.scrollHeight +
      30 +
      nearPlacesRef.current.scrollHeight;

    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
  }

  if (e.target.id === "Planos") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30 +
      featuresRef.current.scrollHeight +
      30 +
      nearPlacesRef.current.scrollHeight +
      locationRef.current.scrollHeight;

    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
  }
  let blueprintSize =
    blueprints.length > 0 ? blueprintsRef.current.scrollHeight : 0;
  if (e.target.id === "Simulador") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30 +
      featuresRef.current.scrollHeight +
      30 +
      nearPlacesRef.current.scrollHeight +
      locationRef.current.scrollHeight +
      blueprintSize;

    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
  }

  if (e.target.id === "Gastos") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30 +
      featuresRef.current.scrollHeight +
      30 +
      nearPlacesRef.current.scrollHeight +
      locationRef.current.scrollHeight +
      blueprintSize +
      calculatorRef.current.scrollHeight +
      10;

    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
  }

  if (e.target.id === "cal&gas") {
    previousSection =
      galleryRef.current.scrollHeight +
      descriptionRef.current.scrollHeight +
      detailsRef.current.scrollHeight +
      30 +
      featuresRef.current.scrollHeight +
      30 +
      nearPlacesRef.current.scrollHeight +
      blueprintSize +
      calculatorRef.current.scrollHeight;

    salesDiv.scrollTo({
      left: 0,
      top: previousSection,
      behavior: "smooth",
    });
  }
}

export const DATA_MENU = [
  {
    id: 1,
    name: "Descripción",
  },
  {
    id: 2,
    name: "Detalles",
  },
  {
    id: 3,
    name: "Características",
  },
  {
    id: 4,
    name: "Lugares",
  },
  {
    id: 5,
    name: "Ubicación",
  },
  {
    id: 8,
    name: "Planos",
  },
  // {
  //   id: 6,
  //   idN: "cal&gas",
  //   name: "Simulador",
  // },
  {
    id: 7,
    name: "Gastos",
  },
];

export const filterMenu = (data, item) => {
  const filtered = data.filter(function (value, index, arr) {
    return value.id !== item;
  });

  return filtered;
};

const replaceDataImage = (images) => {
  if (!!images) {
    const resp = [];
    images.forEach(({ url, order, type_img, title, comment }) => {
      resp.push({
        url,
        orden: order,
        type_img: type_img,
        title,
        description: comment,
      });
    });
    return resp;
  }
  return null;
};

export const replaceDataMX = (data) => {
  const { property, interest_place, icon_details } = data;
  const {
    title: titulo,
    images,
    description: descripcion,
    url_360,
    property_detail,
    property_characteristics,
  } = property;

  const newDataImage = replaceDataImage(images);
  const {
    area,
    property_type: tipo_inmueble,
    income_level: estrato,
    building_set: conjunto,
    address: direccion,
    price: precio_venta,
    price_old: precio_anterior,
    latitude: latitud,
    longitude: longitud,
    suburb: barriocomun,
    avaliable: disponible_para,
    hotbed_property: semillero_propietarios,
    email: correo,
    inventory_type_id,
    property_moment_id,
    city_name: ciudad,
    remodeled: remodelation,
    room_num: num_habitaciones,
    elevator_num: num_ascensores,
    flat,
    bath: banos,
    garage: garajes,
    phone: telefono,
    years_old: anos_antiguedad,
    last_admin_price,
    is_private,
    metropolitan_zone: zona_metropolitana,
    contact_zone: contacto_zona,
    median_zone: zona_mediana,
  } = property_detail;
  return {
    property: {
      images: newDataImage,
      url_360,
      servicios: [],
      titulo,
      descripcion,
      detalles_propiedad: {
        area,
        tipo_inmueble,
        estrato,
        conjunto,
        direccion,
        precio_venta,
        precio_anterior,
        discount_rate: "",
        barriocomun,
        meta_title: "",
        disponible_para,
        semillero_propietarios,
        correo,
        conjunto_edificio: conjunto,
        inventory_type_id,
        property_moment_id,
        ciudad,
        remodelation,
        num_habitaciones,
        num_ascensores,
        num_piso: flat,
        banos,
        garajes,
        telefono,
        anos_antiguedad,
        last_admin_price,
        is_private,
        zona_metropolitana,
        contacto_zona,
        zona_mediana,
        latitud,
        longitud,
      },
      caracteristicas_propiedad: property_characteristics,
    },
    icon_details,
    sitios_interes: interest_place,
  };
};

const iconList = {
  centro_comercial: {
    icon: <LocalMallIcon className="icon" />,
  },
  transmilenio: {
    icon: <DirectionsBusIcon className="icon" />,
  },
  supermercados: {
    icon: <ShoppingCartIcon className="icon" />,
  },
  parques: {
    icon: <NatureIcon className="icon" />,
  },
  colegios: {
    icon: <SchoolIcon className="icon" />,
  },
  aeropuertos: {
    icon: <FlightIcon className="icon" />,
  },
  universidades: {
    icon: <SchoolIcon className="icon" />,
  },
  bibliotecas: {
    icon: <LocalLibraryIcon className="icon" />,
  },
  "bus stop": {
    icon: <DirectionsBusIcon className="icon" />,
  },
  clinicas: {
    icon: <LocalHospitalIcon className="icon" />,
  },
  hospitales: {
    icon: <LocalHospitalIcon className="icon" />,
  },
  "comisaria de policia": {
    icon: <LocalPoliceIcon className="icon" />,
  },
  embajadas: {
    icon: <AccountBalanceIcon className="icon" />,
  },
  "estacion de metro": {
    icon: <DirectionsTransitIcon className="icon" />,
  },
  "estaciones de metrobus": {
    icon: <DirectionsSubwayIcon className="icon" />,
  },
  estadios: {
    icon: <LocalActivityIcon className="icon" />,
  },
  gimnasios: {
    icon: <FitnessCenterIcon className="icon" />,
  },
  museos: {
    icon: <MuseumIcon className="icon" />,
  },
  notarias: {
    icon: <ListAltIcon className="icon" />,
  },
  teatros: {
    icon: <TheatersIcon className="icon" />,
  },
  default: {
    icon: <EditLocationIcon className="icon" />,
  },
};

export const placesData = (item) => {
  const data = iconList[item];
  return !!data ? data.icon : iconList.default.icon;
};

export const showNearPlaces = (data) => {
  let show = false;
  Object.keys(data).map((categoria) => {
    if (data[categoria].length > 0) {
      return (show = true);
    }
  });
  return show;
};
