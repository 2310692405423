import styled from "styled-components";
import pin from "static/assets/images/icons/pin.svg";

export const MarkerWrapper = styled.div`
  .pin {
    border-radius: 50%;
    background: #00cae9;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -28px 0 0 -69px;
  }
  .pin:after {
    content: "";
    width: 72px;
    height: 72px;
    margin: 21px 0 0 16px;
    background-image: url(${pin});
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(47deg);
    position: absolute;
    border-radius: 10%;
  }

  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }

  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0 0px 0px 0;
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: absolute;
    margin: -19px 0 0 -23px;
    -webkit-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 16px #aaa7fc;
    -webkit-animation-delay: 1.1s;
    animation-delay: 7.1s;
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }

    80% {
      transform: translateY(-10px) rotate(-45deg);
    }

    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }
`;
