import React from "react";
import { Calculator, PrettoSlider } from "./style";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import { isMobileOnly } from "react-device-detect";

const CalculatorSide = (props) => {
  return true ? (
    <></>
  ) : (
    <Calculator id="calculadora">
      <div className="toggles">
        <div className="tittle-section">
          {" "}
          SIMULADOR FINANCIERO & GASTOS MENSUALES
        </div>
        <div className="menu-toggle">
          <div
            className={"toggle-left " + props.activeCredito}
            onClick={props.handleChangeTipoCredito.bind(this, "credito")}
          >
            Crédito hipotecario
          </div>
          <div
            className={"toggle-right " + props.activeLeasing}
            onClick={props.handleChangeTipoCredito.bind(this, "leasing")}
          >
            Leasing habitacional
          </div>
        </div>
      </div>
      <div className="detalles">
        <div className="name">
          <p>
            <b>Cuota mensual*</b>
          </p>
          <p>Valor del inmueble</p>
          <p>Valor del crédito</p>
        </div>
        <div className="value">
          <p>
            <b>${props.cuotaMensual}</b>
          </p>
          <p>${new Intl.NumberFormat().format(props.valorInmueble)}</p>
          <p>${props.valorCredito}</p>
        </div>
      </div>
      <div className="controls">
        <div className="title-initial">
          <p className="title-init">
            Cuota inicial{" "}
            <b className="tab-years">{props.cuotaInicialPercentage}%</b>{" "}
            <ArrowRightAltRoundedIcon />{" "}
            <b className="tab-years">
              <span>${props.cuotaInicial}</span>
            </b>
          </p>
        </div>
        {isMobileOnly ? (
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={20}
            onChange={props.handleChangeCuota}
            value={props.cuotaInicialPercentage}
            className="slider-cuota-mob"
            valueLabelFormat={`${props.cuotaInicialPercentage}%`}
            min={props.tipoCredito == "leasing" ? 10 : 30}
          />
        ) : (
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={20}
            onChange={props.handleChangeCuota}
            value={props.cuotaInicialPercentage}
            className="slider-cuota"
            valueLabelFormat={`${props.cuotaInicial}${" "}(${
              props.cuotaInicialPercentage
            }%)`}
            min={props.tipoCredito == "leasing" ? 10 : 30}
          />
        )}

        <div className="title-initial" style={{ marginTop: "32px" }}>
          <p className="title-init">
            Crédito diferido a{" "}
            <b className="tab-years">{props.plazoYears} años</b>
          </p>
        </div>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          defaultValue={20}
          min={5}
          max={30}
          value={props.plazoYears}
          onChange={props.handleChangeYears}
        />
        <p className="note">
          * Cuota calculada con plan de amortización en pesos y tasa M.V del
          <b> 0.8%.</b>
        </p>
      </div>
    </Calculator>
  );
};
export default CalculatorSide;
