import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import TextInput from '../sharedComponents/TextInput/textInput';
import { FormWrapper } from './style';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import Button from '../sharedComponents/Button/button';

const FormContact = props => {
  const [snackMessage, setSnackMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .min(10, 'Por favor ingrese un nombre valido minimo 10 caracteres')
      .max(50, 'Por favor ingrese un nombre valido')
      .required('El nombre es requerido, por favor ingrese uno.'),
    phone: Yup.string()
      .min(7, 'Por favor ingrese un número valido')
      .max(12, 'Por favor ingrese un número valido')
      .required('El telefono es requerido, por favor ingrese uno.'),
    email: Yup.string()
      .email('Por favor ingrese un email valido')
      .required('El email es requerido, por favor ingrese uno'),
  });

  const handleSearch = values => {
    const form = values;
    const properties = [
      { property: 'firstname', value: form.name },
      { property: 'phone', value: form.phone },
      { property: 'email', value: form.email },
      { property: 'interes', value: 'compra' },
      { property: 'interes_proyecto', value: props.proyecto },
      { property: 'fuente', value: 'WEB_registro' }
    ];
    const data = { properties: properties };
    const rootUrl = process.env.REACT_APP_URL_ENDPOINT_HUBSPOT;
    const endpointUrl = process.env.REACT_APP_URL_ENDPOINT_HUBSPOT_CREATE_CONTACT;
    const url = rootUrl + endpointUrl;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => { });
    if (form.name !== '' && form.phone !== '' && form.email !== '') {
      setSnackMessage('Gracias, te contactaremos lo más pronto posible.');
      setOpen(true);
      setTimeout(() => {
        props.toggle();
      }, 3000);
    }
  };
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  return (
    <FormWrapper>
      <div className="form-contact">
        <div className="title">
          <h1>Contáctanos</h1>
        </div>
        <div className="subtitle">
          <h3>Déjanos hablar contigo y resolver todas tus dudas.</h3>
        </div>
        <div className="contact-form">
          <Formik
            enableReinitialize
            validationSchema={ContactSchema}
            initialValues={{
              name: '',
              phone: '',
              email: '',
            }}
            onSubmit={values => handleSearch(values)}
          >
            {props => (
              <Form id="formFilter">
                <div className="name">
                  <TextInput
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    id="name"
                    label="Nombres y apellidos"
                    className="input-filter"
                  />
                </div>
                <div className="phone">
                  <TextInput
                    name="phone"
                    errors={props.errors}
                    touched={props.touched}
                    id="phone"
                    label="Celular de contacto"
                    className="input-filter"
                  />
                </div>
                <div className="email">
                  <TextInput
                    name="email"
                    errors={props.errors}
                    touched={props.touched}
                    id="email"
                    label="Correo electrónico"
                    className="input-filter"
                    type="email"
                  />
                </div>

                <div className="btn-container">
                  <Button
                    id="Buscar"
                    typeClass="primary"
                    type="submit"
                    className="d-inline-block"
                    label="Enviar"
                  ></Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </FormWrapper>
  );
};

export default FormContact;
