import styled from "styled-components";
import {
  size,
  purple,
  archiaBoldFamily,
  black,
  white,
  dark_purple,
} from "StylesConstants";

export const MenuLink = styled.button`
  border: none;
  background-color: transparent;
  color: ${dark_purple};
  vertical-align: middle;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  align-self: center;
  justify-self: center;
  outline: 0;

  &:hover,
  :focus,
  :active {
    text-decoration: none;
    color: ${white};
    cursor: pointer;
    outline: 0;
  }
`;

export const HeaderContainer = styled.div`
  /* border: 4px solid ${purple}; */

  .header-container {
    display: grid;
    grid-template-columns: 80px 1fr 1fr 80px;
    height: 80px;
    align-items: center;

    .sell {
      text-align: right;
      .sell-link {
        position: relative;
        justify-self: start;
        background-color: white;
        border-radius: 100px;
        padding: 15px;
        line-height: 17px;
        font-weight: 600;
        color: ${black};
        :hover {
          text-decoration: underline;
          color: ${black};
        }
      }
    }

    .buy {
      .buy-link {
        position: relative;
        justify-self: start;
        background-color: white;
        border-radius: 100px;
        padding: 15px;
        line-height: 17px;
        font-weight: 600;
        color: ${purple};

        :visited {
          color: ${purple};
        }
        :hover {
          text-decoration: underline;
          color: ${purple};
        }
      }
      text-align: left;
      .data-contact {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 27px;
        color: ${black};
      }
    }

    .title {
      font-style: normal;
      font-family: ${archiaBoldFamily};
      font-size: 11px;
      line-height: 14px;
      color: ${purple};
    }

    .habi-logo {
      img {
        width: 35px;
      }
    }

    .vector {
      text-align: right;

      .text-habi {
        position: absolute;
        right: 9px;
        top: 40px;
        color: #ffffff;
        font-size: 10px;
        color: ${white};
      }

      img {
        height: 80px;
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .header-container {
      display: grid;
      grid-template-columns: 150px 1fr 1fr 150px;

      .visit {
        .link-page {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: ${black};
        }
        .data-contact {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: ${black};
        }
      }

      .contact {
        .data-contact {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: ${black};
        }
      }

      .title {
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        color: ${purple};
      }

      .habi-logo {
        img {
          width: 60px;
        }
      }

      .vector {
        text-align: right;

        .text-habi {
          position: relative;
          left: 60px;
          top: 0;
          font-size: 12px;
          color: ${white};
        }

        img {
          height: 80px;
        }
      }
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .header-container {
      display: grid;
      grid-template-columns: 210px 1fr 1fr 210px;

      .vector {
        text-align: right;

        .text-habi {
          position: relative;
          left: 85px;
          top: 0;
          font-size: 12px;
          color: ${white};
        }

        img {
          height: 80px;
        }
      }
    }
  }
`;
