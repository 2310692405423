import React, { useEffect, useState, Fragment, useRef } from 'react';
import { DetailGalleryWrapper, ImageMiniature } from './style';
import ModalGallery from './ModalGallery';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { isMobileOnly, isTablet, isBrowser } from 'react-device-detect';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Carousel from 'react-multi-carousel';

const DetailGallery = props => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [aparment, setAparment] = useState('');
  const [barrio, setBarrio] = useState('');
  const [idAparment, setIdAparment] = useState('');
  const panorama = useRef(null);

  useEffect(() => {
    setOpen(props.open);
    if (isMobileOnly) {
      setIndex(props.index);
    } else {
      setIndex(props.index == 0 ? 0 : props.index - 1);
    }
    setImages(props.images);
    setAparment(props.apto);
    setBarrio(props.barrio);
    setIdAparment(props.idApto);
  }, [props.open, props.index, props.apto, props.barrio, props.idApto]);

  const backPhoto = e => {
    if (index != 0) {
      setIndex(index - 1);
      panorama.current.scrollTo({
        left: index - 1 == 0 || index - 1 == 1 || index - 1 == 2 ? 0 : 60 * index,
        behavior: 'smooth',
      });
    }
  };
  const forwardPhoto = e => {
    if (index != images.length - 1) {
      setIndex(index + 1);

      panorama.current.scrollTo({
        left: 75 * index,
        behavior: 'smooth',
      });
    }
  };

  const onClickMiniature = e => {
    e.persist();
    let position = e.target.attributes[0]['value'];
    position = parseInt(position);
    setIndex(position);
    panorama.current.scrollTo({
      left: position == 0 || position == 1 ? 0 : e.clientX / 2,
      behavior: 'smooth',
    });
  };

  const getImageThumbnail = imageUrl => {
    const sizes = {
      desktop: 1100,
      tablet: 765,
      mobile: 414,
    };

    if (isMobileOnly) {
      return imageUrl;
    }

    if (isBrowser) {
      const size = sizes.desktop;
      return imageUrl.replace('765', size);
    }

    if (isTablet) {
      const size = sizes.tablet;
      return imageUrl.replace('414', size);
    }
  };

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
    },
  };

  return (
    <Fragment>
      {open && (
        <ModalGallery>
          <div className="gallery-modal">
            <DetailGalleryWrapper
              open={open}
              imagePrincipal={images[index].url}
              isMobileOnly={isMobileOnly}
            >
              <div className="content-modal">
                <div className="header-gallery">
                  <div className="indicator-photos">
                    <p>
                      Foto {index + 1} de {images.length}
                    </p>
                  </div>
                  <div className="content-close">
                    <div>
                      <p className="text" onClick={props.closeModal}>
                        Cerrar
                      </p>
                      <CloseRoundedIcon onClick={props.closeModal} className="icon" />
                    </div>
                  </div>
                </div>
                <div className="body-gallery">
                  <div className="viewer">
                    <div className="control-gallery">
                      <div className="button-ctrl" onClick={backPhoto}>
                        <ArrowBackIosRoundedIcon className="icon" />
                      </div>
                    </div>
                    <div className="fullscreen-viewer">
                      {isMobileOnly ? (
                        <Carousel
                          responsive={responsive}
                          ssr={false}
                          slidesToSlide={1}
                          draggable
                          swipeable
                          arrows
                          removeArrowOnDeviceType={['mobile']}
                          customLeftArrow={<ChevronLeftIcon className="modal-button button-left" />}
                          customRightArrow={
                            <ChevronRightIcon
                              id="arrow-next-click"
                              className="modal-button button-right"
                            />
                          }
                          minimumTouchDrag={20}
                          sliderClass="slider-modal"
                          itemClass="item-modal"
                          containerClass="container"
                          dotListClass="dot-list"
                          showDots
                          renderDotsOutside
                          customTransition={'transform 300ms ease-in-out'}
                        >
                          {images &&
                            images.map((image, i) => {
                              return (
                                <img
                                  key={i + images.length}
                                  className="carousel-image"
                                  src={image.url}
                                  alt={image.description}
                                />
                              );
                            })}
                        </Carousel>
                      ) : (
                        <img
                          src={getImageThumbnail(images[index].url)}
                          alt={`${aparment}-${barrio}-${idAparment}`}
                        />
                      )}
                    </div>
                    <div className="control-gallery">
                      <div className="button-ctrl" onClick={forwardPhoto}>
                        <ArrowForwardIosRoundedIcon className="icon" />
                      </div>
                    </div>
                  </div>
                  {images[index].title ||
                    (images[index].description && (
                      <div className="viewer-description ">
                        <div>
                          {images[index].title && (
                            <div className="title-des">{images[index].title}</div>
                          )}

                          {images[index].description && (
                            <div className="des-viewer">{images[index].description}</div>
                          )}
                        </div>
                      </div>
                    ))}
                  <div className="zone-panorama">
                    <div></div>
                    <div className="viewer-panorama" ref={panorama}>
                      {images.map((image, p) => {
                        return (
                          <div className="box-img">
                            <ImageMiniature
                              image={getImageThumbnail(image.url)}
                              index={index}
                              data-index={p}
                              position={p}
                              key={p}
                              id={`mini-${p}`}
                              altText={`${aparment}-${barrio}-${idAparment}`}
                              onClick={onClickMiniature}
                            ></ImageMiniature>
                          </div>
                        );
                      })}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </DetailGalleryWrapper>
          </div>
        </ModalGallery>
      )}
    </Fragment>
  );
};

export default DetailGallery;
