import React from "react";
import { ServiceLabelWrapper } from "./style";

const serviceLabel = props => {
  return (
    <ServiceLabelWrapper>
      <div className="label-logo-container">
        <div className="label-logo">
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 15.9672V9.81961C20 9.04106 19.6867 8.29575 19.1265 7.74982L12.0152 0.818894C10.8948 -0.272965 9.10515 -0.272965 7.98481 0.818894L0.873487 7.74982C0.313316 8.29575 0 9.04106 0 9.81961V15.9672C0 17.5623 1.29124 18.8583 2.89105 18.8583H17.1137C18.7088 18.8583 20 17.5671 20 15.9672Z"
              fill={props.color}
            />
          </svg>
        </div>
      </div>
      <div className="data-label-container">
        <div className="label-title">
          <p>{props.title}</p>
        </div>
        <div className="label-charge">
          {/* <NumberFormat
            thousandSeparator={true}
            prefix={"$"}
            value={props.charge}
          /> */}
          <p>{`$ ${props.charge}`}</p>
        </div>
      </div>
    </ServiceLabelWrapper>
  );
};

export default serviceLabel;
