import React, { useEffect, useState } from "react";
import { ServicesWrapper, ExpensesWrapper } from "./style";
import { Doughnut } from "react-chartjs-2";
import { LabelWrapper } from "../Services/style";
import ServiceLabel from "../ServiceLabel/serviceLabel";
import { isMobile, isMobileOnly } from "react-device-detect";

const ServicesContainer = (props) => {
  const [services, setServices] = useState([]);
  const [dataService, setDataService] = useState({
    datasets: [
      {
        data: [100000, 20000, 50000, 60000, 70000],
        backgroundColor: [
          "#252129",
          "#7C01FF",
          "#FF5958",
          "#32C1FF",
          "#00F2C3",
        ],
        borderWidth: 5,
        weight: 25,
      },
    ],
  });
  const [dataApi, setDataApi] = useState([]);
  const [totalServices, setTotalServices] = useState([]);
  const [render, setRender] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const dataServices = !!props.services ? props.services : [];
      setRender(false);

      if (dataServices.length > 0) {
        const total =
          dataServices[0].valor +
          dataServices[1].valor +
          dataServices[2].valor +
          dataServices[3].valor +
          parseInt(props.adminPrice);

        setTotalServices(total);
        let data = {
          ...dataService,
          datasets: [
            {
              ...dataService.datasets[0],
              data: [
                props.adminPrice,
                dataServices[3].valor,
                dataServices[2].valor,
                dataServices[0].valor,
                dataServices[1].valor,
              ],
            },
          ],
        };
        setServices(data);
        setDataService(data);
        setDataApi(props.services);
        setRender(true);
      }
    };
    fetchData();
  }, [props.adminPrice, props.services]);

  return render ? (
    <ServicesWrapper ref={props.refGastos}>
      <div className="titulo">
        {!isMobile && <p className="title-services"> MIS GASTOS MENSUALES</p>}
        {isMobileOnly && (
          <div className="valor-inmueble">
            <div className="valor">
              <p className="title-inmueble">Valor del inmueble</p>
              <p className="price">
                ${new Intl.NumberFormat().format(props.valorInmueble)}
              </p>
            </div>
            <div className="cuota">
              <p className="title-inmueble">Cuota mensual</p>
              <p className="price">
                ${new Intl.NumberFormat().format(props.cuotaMensual)}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="graph">
        {services && (
          <Doughnut
            data={dataService}
            options={{
              cutoutPercentage: 80,
              maintainAspectRatio: props.isMobileContainer,
              legend: {
                display: true,
                position: "right",
              },
              animation: false,
            }}
            width={200}
            key={Math.random()}
          />
        )}
        <ExpensesWrapper>
          <div className="total-expenses">
            <p>Total gastos</p>
          </div>
          <div className="charge-expenses">
            <p>{`$ ${new Intl.NumberFormat().format(totalServices)} **`}</p>
          </div>
          <div className="montly-expenses">
            <p>mensuales</p>
          </div>
        </ExpensesWrapper>
      </div>
      <div className="boxes">
        {!isMobile && (
          <div className="valor-inmueble">
            <div>
              <p className="title-inmueble">Valor del inmueble</p>
              <p className="price">
                ${new Intl.NumberFormat().format(props.valorInmueble)}
              </p>
            </div>
            {/* <div>
              <p className="title-inmueble">Cuota financiación</p>
              <p className="price">
                ${new Intl.NumberFormat().format(props.cuotaMensual)}
              </p>
            </div> */}
          </div>
        )}
        <LabelWrapper>
          <ServiceLabel
            title="Administración"
            charge={
              props.adminPrice === 0
                ? "-"
                : new Intl.NumberFormat().format(props.adminPrice)
            }
            color="#252129"
          />
          {dataApi[3] && (
            <ServiceLabel
              title="Telefonía e internet"
              charge={new Intl.NumberFormat().format(dataApi[3].valor)}
              color="#7C01FF"
            />
          )}
          {dataApi[2] && (
            <ServiceLabel
              title="Gas natural"
              charge={new Intl.NumberFormat().format(dataApi[2].valor)}
              color="#FF5958"
            />
          )}
          {dataApi[0] && (
            <ServiceLabel
              title="Agua (bimestral)"
              charge={new Intl.NumberFormat().format(dataApi[0].valor)}
              color="#32C1FF"
            />
          )}
          {dataApi[1] && (
            <ServiceLabel
              title="Luz"
              charge={new Intl.NumberFormat().format(dataApi[1].valor)}
              color="#00F2C3"
            />
          )}
        </LabelWrapper>
      </div>
      <div className="note">
        **La gráfica muestra valores aproximados a los precios encontrados{" "}
        <b>en La Superintendencia de Servicios Públicos Domiciliarios.</b> No
        indica la fracción que corresponde a la cuota mensual financiada.
      </div>
    </ServicesWrapper>
  ) : (
    <></>
  );
};

export default ServicesContainer;
