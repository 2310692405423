import styled from "styled-components";
import {
  size,
  black,
  scarpaGray500,
  scarpaGray400,
  white,
  purple,
  archiaBoldFamily,
} from "StylesConstants";

export let rowsNumber = 2;

export const Gallery = styled.div`
  grid-area: gallery;
  position: relative;
  margin-bottom: 40px;

  .share {
    position: fixed;
    top: 85px;
    right: 5%;
    z-index: 1;
    color: #7c01ff;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    display: grid;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    .icon {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }
  .status-aparment {
    position: absolute;
    top: 40px !important;
    left: -3;
    background: #f3ac33;
    backdrop-filter: blur(0px);
    width: 138px;
    height: 34px;
    border-radius: 0 20px 20px 0;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 8px;
    }
    span {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
      color: #252129;
    }
  }

  .share-withoutMenu {
    position: fixed;
    top: calc(100% - 90%);
    right: 5%;
    z-index: 1;
    color: #7c01ff;
    width: 32px;
    height: 32px;
    margin: auto;
    padding: 3px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    display: grid;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    .icon {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }

  .back {
    position: fixed;
    top: 85px;
    left: 5%;
    z-index: 1;
    color: #2d2d2d;
    width: 32px;
    height: 32px;
    margin: auto;
    padding: 3px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    display: grid;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .icon {
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }

  .back-withoutMenu {
    position: fixed;
    top: calc(100% - 90%);
    left: 5%;
    z-index: 1;
    color: #2d2d2d;
    width: 32px;
    height: 32px;
    margin: auto;
    padding: 4px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

    &:hover {
      cursor: pointer;
    }
    .icon {
      margin: -3px 1px 0px 3px;
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }
  .photos-bt {
    display: none;
  }

  @media screen and (min-width: ${size.tablet}) {
    .status-aparment {
      position: absolute;
      top: 18px !important;
      left: -1px;
    }
    .share {
      top: 18px;
      right: 4.5%;
      left: inherit;
      position: absolute;
      color: ${purple};
      background-color: ${white};
      border-radius: 17px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 135px;

      .icon {
        margin-right: 8px;
      }
      p {
        margin: 0;
      }
      &:hover {
        cursor: pointer;
      }
      .icon {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      .share-name {
        display: block;
      }
    }

    .photos-bt {
      bottom: 16px;
      right: 30px;
      position: absolute;
      margin: 0 auto;
      color: ${purple};
      background-color: ${white};
      border-radius: 17px;
      height: 35px;
      padding: 5px 10px 5px 16px;
      display: flex;
      width: 147px;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
      .icon {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        margin-right: 8px;
      }
      .share-name {
        display: block;
        margin: 0;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .status-aparment {
      top: 18px;
      position: absolute;
    }
    .photos-bt {
      bottom: 16px;
      right: 30px;
      position: absolute;
      margin: 0 auto;
      background-color: ${white};
      border-radius: 17px;
      height: 34px;
      padding: 8px 16px;
      display: flex;
      width: 145px;

      &:hover {
        cursor: pointer;
      }
      .icon {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        margin-right: 6px;
      }

      .share-name {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-left: 10px;
        color: ${purple};
      }
    }

    .share {
      top: 18px;
      right: 30px;
      position: absolute;
      color: ${purple};
      background-color: ${white};
      border-radius: 17px;
      height: 34px;
      display: flex;
      width: 127px;
      padding: 8px 18px;

      &:hover {
        cursor: pointer;
      }
      .icon {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      .share-name {
        margin-left: 8px;
        font-size: 14px;
        line-height: 18px;
        font-family: "Open Sans";
        font-weight: normal;
        font-style: normal;
      }
    }
  }
`;

export const GalleryWrapper = styled.div`
  grid-area: newgallery;
  display: none;
  @media screen and (min-width: ${size.tablet}) {
    display: grid;

    height: 381px;
    border: 2px solid ${black};
    width: 100%;
    grid-template-columns: ${(props) => props.columns};

    .principal-photo {
      border-right: 1px solid ${black};
      background: url(${(props) => props.urlImage}) no-repeat center center;
      background-size: cover;
    }
    .secondary-photos {
      display: grid;
      grid-template-rows: repeat(${(props) => props.rows}, 1fr);
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    display: grid;

    height: 508px;
    border: 2px solid ${black};
    width: 100%;
    grid-template-columns: ${(props) => props.columns};

    .principal-photo {
      border-right: 1px solid ${black};
      background: url(${(props) => props.urlImage}) no-repeat center center;
      background-size: cover;
    }
    .secondary-photos {
      display: grid;
      grid-template-rows: repeat(${(props) => props.rows}, 1fr);
    }
  }
`;

export const GroupPhotos = styled.div`
  display: grid;
  @media screen and (min-width: ${size.tablet}) {
    display: grid;
    grid-template-columns: repeat(${(props) => props.photosColumns}, 1fr);
    .photo {
      border: 1px solid ${black};
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    display: grid;
    grid-template-columns: repeat(${(props) => props.photosColumns}, 1fr);
  }
`;

export const Photo = styled.div`
  @media screen and (min-width: ${size.tablet}) {
    border: 1px solid ${black};
    background: url(${(props) => props.image}) no-repeat center center;
    background-size: cover;
  }
  @media screen and (min-width: ${size.desktop}) {
    border: 1px solid ${black};
    background: url(${(props) => props.image}) no-repeat center center;
    background-size: cover;
  }
`;

export const DetailGalleryWrapper = styled.div`
  display: ${(props) => (props.open ? "grid" : "none")};
  position: relative;
  height: 100%;
  .back {
    position: fixed;
    top: 85px;
    left: 5%;
    z-index: 1;
    color: #2d2d2d;
    width: 32px;
    height: 32px;
    margin: auto;
    padding: 3px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    display: grid;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    .icon {
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }

  .back-withoutMenu {
    position: fixed;
    top: calc(100% - 90%);
    left: 5%;
    z-index: 1;
    color: #2d2d2d;
    width: 32px;
    height: 32px;
    margin: auto;
    padding: 4px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

    &:hover {
      cursor: pointer;
    }
    .icon {
      margin: -3px 1px 0px 3px;
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }

  .share-withoutMenu {
    position: fixed;
    top: calc(100% - 90%);
    right: 5%;
    z-index: 1;
    color: #7c01ff;
    width: 32px;
    height: 32px;
    margin: auto;
    padding: 3px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

    &:hover {
      cursor: pointer;
    }
    .icon {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin: -3px 1px 0px 3px;
      font-size: 18px;
    }
    .share-name {
      display: none;
    }
  }
  .content-modal {
    display: grid;
    grid-template-rows: 64px 1fr;
    height: 100%;
    width: 100%;
    .header-gallery {
      padding: 16px 18px;
      text-align: right;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #2d2d2d;
      margin-right: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      display: grid;
      align-items: center;
      .group-left {
        display: grid;
        .indicator-photos {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: ${scarpaGray500};
          text-align: left;
          padding-left: 22px;
        }
      }
      .group-rigth {
        float: right;
        padding-right: 20px;
        .conten {
          display: inline-flex;
          align-items: center;
          p {
            margin: 0;
          }
        }
        .text {
          cursor: pointer;
        }
        .icon {
          margin-left: 9.4px;
          cursor: pointer;
        }
      }
      .indicator-photos {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: ${scarpaGray500};
        text-align: left;
        display: grid;
        p {
          margin: 0;
        }
      }
      .content-close {
        display: grid;
        justify-content: right;
        div {
          display: inline-flex;
          align-items: center;
          p {
            margin: 0;
          }
        }
      }
    }
    .body-gallery {
      display: grid;
      grid-template-rows: 10% 50% 20%;
      grid-template-areas: "indicator" "fullscreen" "description";

      .indicator-photos {
        font-family: Open Sans;
        grid-area: indicator;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: ${scarpaGray500};
        text-align: left;
        padding-left: 32px;
        display: none;
      }
      .viewer {
        display: grid;
        grid-area: fullscreen;
        grid-template-columns: 100%;
        .control-gallery {
          margin: 0 30px;
          display: grid;
          align-items: center;

          display: none;
          .button-ctrl {
            width: 56px;
            height: 56px;
            left: 0px;
            top: 0px;
            padding: 13px;
            background: rgba(234, 234, 235, 0.48);
            backdrop-filter: blur(8px);
            border-radius: 28px;
            &:hover {
              cursor: pointer;
            }
            .icon {
              color: ${scarpaGray500};
            }
          }
        }
        .fullscreen-viewer {
          border-radius: 16px;
          display: inline-block;
          vertical-align: bottom;
          height: 100%;
          width: 100%;
          min-height: 1px;

          .container {
            max-width: 100vw;
            padding: 0px;

            .slider-modal {
              .item-modal {
                .item-image {
                  margin: 0 0 16px 0;
                  display: block;
                }
              }
            }
          }
          .dot-list {
            position: initial;

            button {
              width: 9px;
              height: 9px;
            }
          }
          img {
            object-fit: scale-down;
            vertical-align: bottom;
            height: auto !important;
            width: auto !important;
            position: static !important;
            touch-action: manipulation;
          }
        }
      }

      .viewer-description {
        display: grid;
        grid-area: description;
        grid-template-columns: 100%;
        padding: 5px;

        .title-des {
          text-align: left;
          font-family: "Open Sans SemiBold";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: ${scarpaGray500};
          padding-top: 12px;
        }
        .des-viewer {
          text-align: left;
          font-family: Open Sans;
          font-style: regular;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: ${scarpaGray400};
          padding-bottom: 24px;
        }
      }
      .zone-panorama {
        grid-area: panorama;
        display: none;
      }
      .viewer-panorama {
        display: inline-flex;
        text-align: left;
        width: 73vw;
        overflow-x: hidden;
        overflow-y: hidden;
      }
    }
  }
  @media screen and (min-width: ${size.tablet}) {
    ${(props) =>
      !props.isMobileOnly
        ? `  .content-modal {
      display: grid;
      grid-template-rows: 81px 95% !important;
      height: 100%;
      width: 100%;
      .header-gallery {
        padding: 31px 32px 0 32px;
        text-align: right;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #2d2d2d;
        grid-template-columns: 1fr 1fr;
        display: grid;
        align-items: center;
        .group-left {
          display: none;
        }
        .group-right {
          display: inline-flex;
          .text {
            padding-top: 4px;
            cursor: pointer;
          }
          .icon {
            margin-left: 9.4px;
            cursor: pointer;
          }
        }
        .indicator-photos {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: ${scarpaGray500};
          text-align: left;
          padding-left: 32px;
          display: grid;
          p {
            margin: 0;
          }
        }
        .content-close {
          display: grid;
          justify-content: right;
          div {
            display: inline-flex;
            align-items: center;
            p {
              margin: 0;
            }
          }
        }
      }
      .body-gallery {
        display: grid;
        grid-template-rows: 5% 10% 65% 20%;
        grid-template-areas: 'indicator' 'panorama' 'fullscreen' 'description';

        .indicator-photos {
          font-family: 'Open Sans';
          grid-area: indicator;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: ${scarpaGray500};
          text-align: left;
          padding-left: 32px;
          display: grid;
        }
        .viewer {
          display: grid;
          grid-area: fullscreen;
          grid-template-columns: 13% 74% 13%;
          .control-gallery {
            margin: auto;
            display: grid;

            .button-ctrl {
              width: 56px;
              height: 56px;
              left: 0px;
              top: 0px;
              padding: 13px;
              background: rgba(234, 234, 235, 0.48);
              backdrop-filter: blur(8px);
              border-radius: 28px;
              &:hover {
                cursor: pointer;
              }
              .icon {
                color: ${scarpaGray500};
              }
            }
          }
          .fullscreen-viewer {
            border-radius: 16px;
            display: inline-block;
            vertical-align: bottom;
            height: 100%;
            width: 100%;
            min-height: 1px;
            img {
              object-fit: scale-down;
              vertical-align: bottom;
              height: 100% !important;
              width: 100% !important;
              position: static !important;
            }
          }
        }

        .viewer-description {
          display: grid;
          grid-area: description;
          grid-template-columns: 13% 74% 13%;

          .title-des {
            text-align: left;
            font-family: 'Open Sans SemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: ${scarpaGray500};
            padding-top: 12px;
          }
          .des-viewer {
            text-align: left;
            font-family: 'Open Sans';
            font-style: regular;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: ${scarpaGray400};
            padding-bottom: 24px;
          }
        }
        .zone-panorama {
          grid-area: panorama;
          display: block;
        }
        .viewer-panorama {
          display: inline-flex;
          text-align: left;
          width: 73vw;
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }
    }`
        : ``}
  }

  @media screen and (min-width: ${size.desktop}) {
    .share-withoutMenu {
      top: 3%;
      right: 10%;
      position: absolute;
      margin: 0 auto;
      color: ${purple};
      background-color: ${white};
      border-radius: 17px;
      height: 35px;
      padding: 5px 10px;
      display: flex;
      width: 135px;
      &:hover {
        cursor: pointer;
      }
      .icon {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        margin: -3px 1px 0px 3px;
      }
    }

    .content-modal {
      display: grid;
      grid-template-rows: 80px 95%;
      height: 100%;
      width: 100%;
      .header-gallery {
        padding: 0 32px;
        text-align: right;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #2d2d2d;
        margin-right: 33px;
        grid-template-columns: 1fr 1fr;
        height: 80px;
        display: grid;
        align-items: center;

        .group-left {
          display: none;
        }
        .group-right {
          display: inline-flex;
          .text {
            padding-top: 4px;
            cursor: pointer;
          }
          .icon {
            margin-left: 9.4px;
            cursor: pointer;
          }
        }

        .indicator-photos {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: ${scarpaGray500};
          text-align: left;
          padding-left: 32px;
          display: grid;
          p {
            margin: 0;
          }
        }
        .content-close {
          display: grid;
          justify-content: right;
          div {
            display: inline-flex;
            align-items: center;
            p {
              margin: 0;
            }
          }
        }
      }
      .body-gallery {
        display: grid;
        grid-template-rows: 70vh 24px auto;
        grid-template-areas: "fullscreen" "description" "panorama";

        .viewer {
          display: grid;
          grid-template-columns: 120px 1fr 120px;
          padding: 0;
          .control-gallery {
            margin: auto;

            .button-ctrl {
              width: 56px;
              height: 56px;
              left: 0px;
              top: 0px;
              padding: 13px;
              background: rgba(234, 234, 235, 0.48);
              backdrop-filter: blur(8px);
              border-radius: 28px;
              &:hover {
                cursor: pointer;
              }
              .icon {
                color: ${scarpaGray500};
              }
            }
          }
          .fullscreen-viewer {
            display: inline-block;
            vertical-align: bottom;
            height: 100%;
            width: 100%;
            min-height: 1px;
            img {
              object-fit: scale-down;
              vertical-align: bottom;
              height: auto !important;
              width: auto !important;
              position: static !important;
            }

            border-radius: 16px;
          }
        }

        .viewer-description {
          display: grid;
          grid-template-columns: 13% 74% 13%;
          .title-des {
            text-align: left;
            font-family: Open Sans SemiBold;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: ${scarpaGray500};
            padding-top: 12px;
          }
          .des-viewer {
            text-align: left;
            font-family: Open Sans;
            font-style: regular;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: ${scarpaGray400};
            padding-bottom: 24px;
          }
        }
        .zone-panorama {
          grid-area: panorama;
          grid-template-columns: 120px 1fr 120px;
          display: grid;
        }
        .viewer-panorama {
          display: inline-flex;
          text-align: left;
          width: 100%;
          overflow: hidden;
          height: 66px;
          .box-image {
            height: 48px;
            width: 48px;
          }
        }
      }
    }
  }
`;

export const ImageMiniature = styled.img.attrs((props) => ({
  src: props.image,
  alt: props.altText,
}))`
  width: 64px !important;
  height: 64px !important;
  left: 80px;
  margin-right: 16px;
  border: ${(props) =>
    props.index == props.position ? " 3px solid #FF5958" : "none"};
  filter: ${(props) =>
    props.index == props.position ? "opacity(1)" : "opacity(0.7)"};
  background-size: cover;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const InfoBannerGallery = styled.div`
  z-index: 1;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  width: 131px;
  height: 32px;
  border-radius: 0 25px 25px 0;
  font-family: ${archiaBoldFamily};
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 12px;
  font-size: 13px;
  position: absolute;
  top: 72px;

  .flag-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
`;

ImageMiniature.defaultProps = {
  image: "",
  index: 0,
  position: 0,
  altText: "",
};

DetailGalleryWrapper.defaultProps = {
  open: "",
  imageMiniature: "",
  imagePrincipal: "",
  isMobileOnly: false,
};

Photo.defaultProps = {
  image: "",
};

GroupPhotos.defaultProps = {
  photosColumns: "",
  heightGroups: "0",
};

GalleryWrapper.defaultProps = {
  rows: 2,
  columns: "1fr 1fr",
  heightGroups: "0",
  urlImage: "",
  quantity: 1,
};
