import React, { useState, useEffect } from "react";
import { CalculatorAndServiceWrapper } from "./style";
import CalculatorSide from "./calculatorSide";
import { Collapse, CardBody, Card } from "reactstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { isMobileOnly, isMobile } from "react-device-detect";
import ServicesContainer from "../Services/servicesContainer";

const CreditCalculatorContainer = (props) => {
  const [tipoCredito, setTipoCredito] = useState("credito");
  const [cuotaMensual, setCuotaMensual] = useState(0);
  const [cuotaInicial, setCuotaInicial] = useState(30);
  const [cuotaInicialPercentage, setCuotaInicialPercentage] = useState(30);
  const [valorCredito, setValorCredito] = useState(0);
  const [plazoYears, setPlazoYears] = useState(20);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenExpenses, setIsOpenExpenses] = useState(true);

  const toggle = () => setIsOpen(!isOpen);
  const toggleExpenses = () => setIsOpenExpenses(!isOpenExpenses);

  const handleChangeYears = (event, newValue) => {
    setPlazoYears(newValue);
  };

  const handleChangeCuota = (event, newValue) => {
    setCuotaInicialPercentage(newValue);
  };
  const handleChangeTipoCredito = (name, event) => {
    setCuotaInicialPercentage(name == "leasing" ? 10 : 30);
    setTipoCredito(name);
  };
  useEffect(() => {
    const value = props.valorInmueble;
    const montoPrestamo = value - (value * cuotaInicialPercentage) / 100;
    const meses = plazoYears * 12;
    const tasaInteres =
      tipoCredito == "leasing" ? props.tasaLeasing : props.tasaCredito;
    const mensualidad = parseInt(
      (montoPrestamo * tasaInteres) / (1 - Math.pow(1 + tasaInteres, -meses))
    );

    setCuotaInicial(
      new Intl.NumberFormat().format(props.valorInmueble - montoPrestamo)
    );
    setValorCredito(new Intl.NumberFormat().format(montoPrestamo));
    setCuotaMensual(mensualidad);
  });

  const activeLeasing = tipoCredito == "leasing" ? "active" : "";
  const activeCredito = tipoCredito == "credito" ? "active" : "";
  return (
    <CalculatorAndServiceWrapper ref={props.refCal}>
      {props.tasaLeasing !== 0 && props.tasaCredito !== 0 ? (
        <div className="container-services" id="container-services">
          <div className="calculator-side" ref={props.referenceCalculator}>
            {isMobileOnly ? (
              <div className="calculadora-mob">
                {/* <div
                  color="primary"
                  onClick={toggle}
                  className="collapsed-items"
                >
                  <div className="name">SIMULADOR FINANCIERO</div>
                  <div className="icon">
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </div> */}
                <Collapse isOpen={isOpen}>
                  <Card style={{ border: "none" }}>
                    <CardBody style={{ padding: "0" }}>
                      <CalculatorSide
                        activeCredito={activeCredito}
                        handleChangeTipoCredito={handleChangeTipoCredito}
                        cuotaMensual={new Intl.NumberFormat().format(
                          cuotaMensual
                        )}
                        cuotaInicial={cuotaInicial}
                        valorInmueble={props.valorInmueble}
                        valorCredito={valorCredito}
                        cuotaInicialPercentage={cuotaInicialPercentage}
                        handleChangeCuota={handleChangeCuota}
                        plazoYears={plazoYears}
                        tipoCredito={tipoCredito}
                        handleChangeYears={handleChangeYears}
                        activeLeasing={activeLeasing}
                      />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            ) : (
              <CalculatorSide
                id="calculadora"
                activeCredito={activeCredito}
                handleChangeTipoCredito={handleChangeTipoCredito}
                cuotaMensual={new Intl.NumberFormat().format(cuotaMensual)}
                cuotaInicial={cuotaInicial}
                valorInmueble={props.valorInmueble}
                valorCredito={valorCredito}
                cuotaInicialPercentage={cuotaInicialPercentage}
                handleChangeCuota={handleChangeCuota}
                plazoYears={plazoYears}
                tipoCredito={tipoCredito}
                handleChangeYears={handleChangeYears}
                activeLeasing={activeLeasing}
              />
            )}
          </div>
          {props.showBills ? (
            <div className="services-side" ref={props.referenceGastos}>
              {isMobileOnly ? (
                <div className="calculadora-mob">
                  <div
                    color="primary"
                    onClick={toggleExpenses}
                    className="collapsed-items"
                  >
                    <div className="name">MIS GASTOS MENSUALES</div>
                    <div className="icon">
                      {isOpenExpenses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </div>

                  <Collapse isOpen={isOpenExpenses}>
                    <Card style={{ border: "none" }}>
                      <CardBody style={{ padding: "0" }}>
                        <ServicesContainer
                          refGastos={props.referenceGastos}
                          estrato={props.estrato}
                          cuotaMensual={cuotaMensual}
                          adminPrice={props.adminPrice}
                          isMobileContainer={isMobile}
                          valorInmueble={props.valorInmueble}
                          services={props.services}
                        />
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              ) : (
                <ServicesContainer
                  estrato={props.estrato}
                  cuotaMensual={cuotaMensual}
                  adminPrice={props.adminPrice}
                  isMobileContainer={isMobile}
                  refGastos={props.referenceGastos}
                  valorInmueble={props.valorInmueble}
                  services={props.services}
                />
              )}
            </div>
          ) : (
            <div className="services-side" ref={props.referenceGastos}></div>
          )}
        </div>
      ) : (
        <div>
          <div
            className="calculator-side"
            ref={props.referenceCalculator}
          ></div>
          <div className="services-side" ref={props.referenceGastos}></div>
        </div>
      )}
    </CalculatorAndServiceWrapper>
  );
};

export default CreditCalculatorContainer;
