import React, { useEffect, useState } from "react";
import { GalleryMobileWrapper } from "./style";
import ModalGalleryMobile from "../ModalGalleryMobile";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
const GalleryMobile = (props) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    let newImages = [];
    let counter = 0;
    props.images.map((image, pos) => {
      if (counter == 0) {
        newImages.push({
          index: pos,
          url: image.url,
          class: "complete",
          title: image.title,
          description: image.description,
        });
        counter = counter + 1;
      } else {
        newImages.push({
          index: pos,
          url: image.url,
          class: "shared",
          title: image.title,
          description: image.description,
        });
        counter = counter + 1;

        if (counter == 3) {
          counter = 0;
        }
      }
    });
    setImages(newImages);
  }, [props.images]);

  const clickImage = (index) => {
    props.clickImagen(index);
  };

  const close = (e) => {
    props.closeGallery();
  };
  return (
    <ModalGalleryMobile>
      <GalleryMobileWrapper>
        <div className="header-mob-gal">
          <ArrowBackRoundedIcon onClick={() => close()} />
        </div>
        <div className="distribution">
          {images.map((image) => {
            return (
              <div
                className={image.class}
                onClick={() => clickImage(image.index)}
                key={props.idApto + image.index}
              >
                <img
                  src={image.url}
                  alt={`${props.apto}-${props.barrio}-${props.idApto}`}
                />
              </div>
            );
          })}
        </div>
      </GalleryMobileWrapper>
    </ModalGalleryMobile>
  );
};

export default GalleryMobile;
