import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../Marker/marker';

const MapHabi = ({ centerMap, getAddressPin, reference }) => {
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(centerMap.lat);
  const [lng, setLng] = useState(centerMap.lng);
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({ lat, lng });

  const gmapskey = { key: process.env.REACT_APP_GOOGLE_MAPS_KEY };

  const createMapOptions = maps => {
    return {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: 'cooperative'
    };
  };

  const onCircleInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setLat(mouse.lat);
    setLng(mouse.lng);
  };
  const onChangeMap = ({ center, zoom }) => {
    setCenter(center);
    setZoom(zoom);
  };
  const onCircleInteraction3 = ({ childKey, childProps, mouse }) => {
    setDraggable(true);
    const baseUrl = process.env.REACT_APP_HABI_WEB;
    const basePath = process.env.REACT_APP_ENDPOINT_LLAVERO_API_BASE_PATH;
    const endpoint = process.env.REACT_APP_ENDPOINT_FORM_API_POST_LATLNG2DIR;
    const apikey = process.env.REACT_APP_ENDPOINT_LLAVERO_API_KEY;
    const urlGetFormDynamic = baseUrl + basePath + endpoint;
    const data = {
      latitud: lat,
      longitud: lng,
    };
    fetch(urlGetFormDynamic, {
      method: 'POST',
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        getAddressPin(response.direccion_formato, lat, lng);
      });
  };
  return (
    <div className="mapaHabi" ref={reference} tabIndex={-1}>
      <GoogleMapReact
        bootstrapURLKeys={gmapskey}
        center={center}
        zoom={zoom}
        // draggable={draggable}
        onChange={onChangeMap}
        // onChildMouseDown={onCircleInteraction}
        // onChildMouseUp={onCircleInteraction3}
        // onChildMouseMove={onCircleInteraction}
        options={createMapOptions}
      >
        <Marker lat={lat} lng={lng} name="Ubicación aproximada" color="#7C01FF" />
      </GoogleMapReact>
    </div>
  );
};
export default MapHabi;
