import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './static/assets/fonts/Archia-Regular.otf';
import WebFont from 'webfontloader';
import './index.css';

WebFont.load({
  custom: {
    families: ['Open Sans', 'sans-serif'],
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
