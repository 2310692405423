import React from "react";
import { HeaderContainer, MenuLink } from "./style";
import logo from "static/assets/images/logo/logo_habi.jpg";

const Header = (props) => {
  return (
    <HeaderContainer>
      <div className="header-container">
        <div className="habi-logo">
          <img src={logo} alt="logo-habi" />
        </div>
        <div className="sell">
          {/* <a className="sell-link" href="https://habi.co">             
           Vender
          </a> */}
        </div>
        <div className="buy">
          {/* <a className="buy-link" href="https://habi.co/comprar-apartamento">             
           Comprar
          </a> */}
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
