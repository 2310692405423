import React, { useState, useEffect } from "react";
import { FeaturesContainer } from "../../style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const FeaturesShared = (props) => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (props.properties) {
      setProperties(props.properties);
    }
  }, [props.properties]);

  return (
    <FeaturesContainer ref={props.featuresRef} border={properties.length > 0}>
      {properties.length > 0 && (
        <div>
          <p className="title-description" tabIndex={-1}>
            CARACTERÍSTICAS
          </p>

          <ul className="container-features" tabIndex={-1}>
            {properties
              ? properties.map((feature, i) => (
                  <li className="data-list-item" key={i}>
                    <div className="icon">
                      <CheckCircleIcon />
                    </div>
                    <div className="feature">
                      <span className="feature-text">{feature}</span>
                    </div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </FeaturesContainer>
  );
};
export default FeaturesShared;
