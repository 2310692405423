import React, { useState, useEffect } from "react";
import { NearPlacesContainer } from "../../style";
import person from "static/assets/images/icons/person.svg";
import { placesData, showNearPlaces } from "components/salesData/utils";

const NearPlaces = (props) => {
  const [NearPlaces, setNearPlaces] = useState({});

  useEffect(() => {
    if (props.nearPlaces) {
      setNearPlaces(props.nearPlaces);
    }
  }, [props.nearPlaces]);

  return (
    <NearPlacesContainer
      ref={props.nearPlacesRef}
      hide={!showNearPlaces(NearPlaces)}
    >
      <p className="title-description" tabIndex={-1}>
        LUGARES CERCANOS Y SITIOS DE INTERÉS
      </p>

      <div className="container-places" tabIndex={-1}>
        {Object.keys(NearPlaces).map((categoria, p) => {
          const icon = placesData(categoria);

          let places = Object.keys(NearPlaces[categoria]).map((child, pC) => {
            return (
              <div className="item-category" key={pC}>
                {NearPlaces[categoria][child].name} <span>&#x25cf;</span>
                <img src={person} alt="img person" />{" "}
                {NearPlaces[categoria][child].time} min
              </div>
            );
          });

          return (
            <div className="group-near" key={p}>
              <div className="title-category">
                <span>{icon}</span> <p>{categoria.replace("_", " ")}</p>
              </div>
              {places}
            </div>
          );
        })}
      </div>
    </NearPlacesContainer>
  );
};

export default NearPlaces;
