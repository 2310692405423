import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { CO, MX } from "./es";

const DEFAULT_LANGUAGE = "es-CO";

const resources = {
  "es-CO": {
    translation: CO,
  },
  "es-MX": {
    translation: MX,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
