import styled /*, { keyframes }*/ from "styled-components";
//import { slideInLeft, slideInRight } from 'react-animations';
import {
  size,
  dark_grey,
  archiaBoldFamily,
  black,
  grey,
} from "StylesConstants";

/*const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideInRightAnimation = keyframes`${slideInRight}`;*/

export const MapWrapper = styled.section`
  display: grid;
  grid-area: map;
  grid-template: 1fr;
  grid-template-areas:
    "title"
    "map"
    "options-map";
  padding-bottom: 23px;
  border-bottom: none;

  .title-description {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 16px;
    color: ${black};
    margin-bottom: 0px;
  }
  .mapaHabi {
    position: relative !important;
    width: 100% !important;
    height: 350px !important;
  }

  .options-map {
    text-align: center;
    margin-top: 8px;
  }

  .como-llegar {
    width: 173px;
    height: 48px;
    left: 121px;
    top: 3123px;

    background: #ffffff;
    border: 1px solid #eaeaeb;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 10px 28.84px 10px 24px;
    &:hover {
      cursor: pointer;
    }

    .title-map-op {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-align: center;
      color: #424242;

      flex: none;
      order: 0;
      align-self: center;
      margin: 7px 0px;
    }
  }
  row-gap: 15px;
  @media (min-width: ${size.tablet}) {
    border-bottom: 1px solid ${grey};

    .mapaHabi {
      position: relative !important;
      width: 100% !important;
      height: 500px !important;
    }
  }
  @media (min-width: ${size.desktop}) {
    border-bottom: none;

    .mapaHabi {
      position: relative !important;
      width: 100% !important;
      height: 410px !important;
      margin: 0;
    }
  }
`;

export const Title = styled.div`
  grid-area: title;
  height: auto;
  position: relative;
  h1 {
    font-family: ${archiaBoldFamily};
    font-size: 24px;
    line-height: 30px;
  }
  span {
    position: initial;
    left: 28%;
    bottom: 17%;
    font-size: 15px;
    font-family: archiaBold;
  }

  @media (min-width: ${size.tablet}) {
    grid-area: title;
    margin-top: 10px;
    height: auto;
    position: relative;
    h1 {
      font-family: ${archiaBoldFamily};
      font-size: 32px;
    }
    span {
      position: absolute;
      left: 28%;
      bottom: 17%;
      font-size: 15px;
      font-family: archiaBold;
    }
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    grid-area: title;
    margin-top: 10px;
    height: auto;
    position: relative;
    h1 {
      font-family: ${archiaBoldFamily};
      font-size: 32px;
    }
    span {
      position: absolute;
      left: 40%;
      bottom: 15%;
      font-size: 25px;
      font-family: ${archiaBoldFamily};
    }
  }
  @media (min-width: 1401px) {
    grid-area: title;
    margin-top: 10px;
    height: auto;
    position: relative;
    h1 {
      font-family: ${archiaBoldFamily};
      font-size: 32px;
    }
    span {
      position: absolute;
      left: 55%;
      bottom: 10%;
      font-size: 25px;
      font-family: ${archiaBoldFamily};
    }
  }
`;

export const Map = styled.div`
  grid-area: map;
  width: 100%;
  justify-self: center;
  .help-hint {
    color: ${dark_grey};
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 10px;
    img {
      width: 20px;
      margin-bottom: 4px;
    }
  }
  @media (min-width: ${size.tablet}) {
    .help-hint {
      color: ${dark_grey};
      margin-bottom: 0;
      margin-top: 10px;
      font-size: 12px;
      img {
        width: 25px;
        margin-bottom: 4px;
      }
    }
  }
  @media (min-width: ${size.desktop}) {
    .help-hint {
      color: ${dark_grey};
      margin-bottom: 0;
      margin-top: 10px;
      font-size: 14px;
      img {
        width: 25px;
        margin-bottom: 4px;
      }
    }
  }
`;
