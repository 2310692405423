import styled from "styled-components";
import PurpleBG from "static/assets/images/backgrounds/Purple360.svg";
import PurpleBGTablet from "static/assets/images/backgrounds/Purple360Tablet.svg";
import PurpleBGDesktop from "static/assets/images/backgrounds/Purple360Desktop.svg";
import { size, scarpaGray000, electricPurple900 } from "StylesConstants";

export const RecorridoContainer = styled.div`
  grid-area: recorrido;

  margin-top: 24px;

  .card {
    display: grid;
    grid-template-areas:
      'iconarea description360'
      'action action';
    grid-template-columns: 30% 1fr;
    background-image: url(${PurpleBG});
    height: 182px;
    border-radius: 16px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: ${electricPurple900};

    .iconarea {
      grid-area: iconarea;
      text-align: center;
      padding-top: 26px;
    }
    .description360 {
      padding-top: 18px;
      grid-area: description360;
      display: grid;
      grid-template-areas:
        'titledes indicator'
        'detailexpand detailexpand';
      grid-template-columns: 70% 1fr;

      .tour {
        grid-area: titledes;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: ${scarpaGray000};
      }
      .indicator360 {
        grid-area: indicator;
        width: 48px;
        height: 17px;
        background: #00f2c3;
        border-radius: 4px;
        color: ${electricPurple900};
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        padding: 2px 4px;
        margin-top: 7px;
      }
      .detailexpand {
        grid-area: detailexpand;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        color: #e6e5e7;
        margin-top: -14px;
        padding-right: 10px;
      }
    }

    .options {
      grid-area: action;
      text-align: center;

      .send-bt {
        width: 256px;
        height: 40px;
        left: 79px;
        top: 1341px;
        display: inline-flex;
        padding: 0 30px;
        background: #00f2c3;
        border-radius: 100px;
        &:hover {
          cursor: pointer;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          color: #190033;
          flex: none;
          order: 0;
          align-self: center;
          margin: 8px 0px;
        }
        .icon-recorrido {
          margin: 7px 0px 7px 8px;
        }
      }
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    .card {
    display: grid;
    grid-template-areas:
      'iconarea description360 action';
    grid-template-columns: 20% 1fr 1fr;
    background-image: url(${PurpleBGTablet});
    height: 116px;
    border-radius: 16px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: ${electricPurple900};

    .iconarea {
      grid-area: iconarea;
      text-align: center;
      padding-top: 26px;
    }
    .description360 {
      padding-top: 18px;
      grid-area: description360;
      display: grid;
      grid-template-areas:
        'titledes indicator'
        'detailexpand detailexpand';
      grid-template-columns: 70% 1fr;

      .tour {
        grid-area: titledes;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: ${scarpaGray000};
      }
      .indicator360 {
        grid-area: indicator;
        width: 48px;
        height: 17px;
        background: #00f2c3;
        border-radius: 4px;
        color: ${electricPurple900};
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        padding: 2px 4px;
        margin-top: 7px;
      }
      .detailexpand {
        grid-area: detailexpand;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        color: #e6e5e7;
        margin-top: -14px;
      }
    }

    .options {
      grid-area: action;
      text-align: center;
      margin-top: 38px;

      .send-bt {
        width: 256px;
        height: 40px;
        left: 79px;
        top: 1341px;
        display: inline-flex;
        padding: 0 30px;
        background: #00f2c3;
        border-radius: 100px;
        &:hover {
          cursor: pointer;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          color: #190033;
          flex: none;
          order: 0;
          align-self: center;
          margin: 8px 0px;
        }
        .icon-recorrido {
          margin: 7px 0px 7px 8px;
        }
      }
    }
  }
  

  @media screen and (min-width: ${size.desktop}) {
    .card {
    display: grid;
    grid-template-areas:
      'iconarea description360 action';
    grid-template-columns: 20% 1fr 1fr;
    background-image: url(${PurpleBGDesktop});
    height: 116px;
    border-radius: 16px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: ${electricPurple900};

    .iconarea {
      grid-area: iconarea;
      text-align: center;
      padding-top: 26px;
    }
    .description360 {
      padding-top: 18px;
      grid-area: description360;
      display: grid;
      grid-template-areas:
        'titledes indicator'
        'detailexpand detailexpand';
      grid-template-columns: 70% 1fr;

      .tour {
        grid-area: titledes;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: ${scarpaGray000};
      }
      .indicator360 {
        grid-area: indicator;
        width: 48px;
        height: 17px;
        background: #00f2c3;
        border-radius: 4px;
        color: ${electricPurple900};
        font-style: normal;
        font-weight: 800;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        padding: 2px 4px;
        margin-top: 7px;
      }
      .detailexpand {
        grid-area: detailexpand;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #e6e5e7;
        margin-top: -4px;
      }
    }

    .options {
      grid-area: action;
      text-align: center;
      margin-top: 38px;

      .send-bt {
        width: 210px;
        height: 40px;
        left: 79px;
        top: 1341px;
        display: inline-flex;
        padding: 0 30px;
        background: #00f2c3;
        border-radius: 100px;
        &:hover {
          cursor: pointer;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          color: #190033;
          flex: none;
          order: 0;
          align-self: center;
          margin: 8px 0px;
        }
        .icon-recorrido {
          margin: 7px 0px 7px 8px;
        }
      }
    }
  }
`;
