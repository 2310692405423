import React, { useState, useEffect } from "react";
import SalesData from "./salesData";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { getCountry, isDefaultCountry } from "utils/utils";
import { replaceDataMX } from "./utils";

const SalesDataContainer = (props) => {
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [valueLeasing, setValueLeasing] = useState(0);
  const [valueCredit, setValueCredit] = useState(0);
  const [nearPlaces, setNearPlaces] = useState({});
  const [services, setServices] = useState([]);

  useEffect(() => {
    let params = props.match.params.ParamName;
    setState({ id: params });
  }, [props.match.params.ParamName]);

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = process.env.REACT_APP_HABI_API_URL_V2;
      const basePath =
        process.env.REACT_APP_ENDPOINT_HABI_CMS_GLOBAL_API_BASE_PATH;
      const endpoint = process.env.REACT_APP_ENDPOINT_GLOBAL_CMS_PROPERTY_CARD;
      const apiKey = process.env.REACT_APP_HABI_GLOBAL_API_CMS_KEY;
      const urlGetFormDynamic = baseUrl + basePath + endpoint;
      console.log("getCountry 1", getCountry());
      setIsLoading(true);
      await axios({
        url: urlGetFormDynamic,
        method: "get",
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
        params: { property_id: state.id, country: getCountry() },
      })
        .then((response) => {
          let data = response.data.data;
          if (!isDefaultCountry()) data = replaceDataMX(data);

          const { property, sitios_interes } = data;
          if (isDefaultCountry()) {
            property.detalles_propiedad.banos =
              property.detalles_propiedad.baños;
          }
          setState({ data: property });
          setNearPlaces(sitios_interes);
          setServices(property.servicios);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          setIsLoading(false);
        });
    };

    const fetchRates = async () => {
      setValueLeasing(0.008);
      setValueCredit(0.008);
    };
    if (state.id) {
      fetchData();
      if (isDefaultCountry()) fetchRates();
    }

    return () => {
      setIsLoading(false);
    };
  }, [state.id]);

  return (
    <SalesData
      isLoading={isLoading}
      dataProperty={state.data}
      tasaLeasing={valueLeasing}
      tasaCredito={valueCredit}
      isHabiWeb={props.isHabiWeb}
      nearPlaces={nearPlaces}
      services={services}
    />
  );
};

export default withRouter(SalesDataContainer);
