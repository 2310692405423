import React, { useState, useEffect } from "react"
import Carousel, { Modal, ModalGateway } from "react-images";

const GalleryModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  let images = [];
  props.images.map((image, pos) => {
    images.push({ src: image });
  });

  return (
    <ModalGateway>
      {modal ? (
        <Modal onClose={props.closeModal} toggle={toggle}>
          <Carousel views={images} currentIndex={props.currentIndex} />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};

export default GalleryModal;
